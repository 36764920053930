import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {crudService} from "../../_services/crud.service";
import {
    CContainer,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CDataTable,
    CCard,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle, CFormGroup, CLabel, CInput, CCardFooter, CForm
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import {alertService} from "../../_services/alert.service";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const fields = [
    {key: 'userName', label: 'Nombre', _style: {width: '20%'}},
    {key: 'requestDateFormatted', label: 'Fecha de solicitud', _style: {width: '20%'}},
    {key: 'amountRequestedFormatted', label: 'Monto', _style: {width: '10%'}},
    {key: 'approvedByName', label: 'Aprobado por', _style: {width: '20%'}},
    {key: 'deniedByName', label: 'Rechazado por', _style: {width: '20%'}},
    {key: 'approvalStatus', label: 'Estado', _style: {width: '20%'}},
    {key: 'denialComment', label: 'Comentarios', _style: {width: '20%'}},
    {key: 'paymentMethodName', label: 'Método de pago', _style: {width: '10%'}},
    {key: 'accountNumber', label: 'Cuenta', _style: {width: '20%'}},
    {
        key: 'show_details',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    },
    {
        key: 'delete',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const Requests = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [requests, setRequests] = useState([])
    const [danger, setDanger] = useState(false)
    const [approve, setApprove] = useState(false)
    const [reasonForDenied, setReasonForDenied] = useState(null)
    const [approvalComment, setApprovalComment] = useState(null)
    const [selectedRequest, setSelectedRequest] = useState({})
    
    function approveRequest() {
        crudService.post('/request/approveOrDeny',
            {
                id: selectedRequest.id,
                isApproved: true,
                denialComment: "",
                approvalComment: approvalComment
            },
            fetchRequests,
            'Ocurrió un error aprobando la recarga.')
    }

    function denyRecharge() {
        crudService.post('/request/approveOrDeny',
            {
                id: selectedRequest.id,
                isApproved: false,
                denialComment: reasonForDenied,
                approvalComment: ""
            },
            fetchRequests,
            'Ocurrió un error denegando la recarga.')
    }

    async function fetchRequests() {
        await crudService.list('request', setRequests, setLoading);
    }

    const renderRequestsTable = () => {
        const handleDeniedRecharge = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (!reasonForDenied) {
                    alertService.error("La razón de rechazo es requerida.");
                    return;
                }

                denyRecharge();
                setDanger(!danger);
            }
        }

        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={danger}
                                onClose={() => setDanger(!danger)}
                                color="danger"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleDeniedRecharge}>
                                        <CFormGroup>
                                            <CLabel>¿Está seguro que desea rechazar la solicitud?</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="reasonForDenied">Razón de rechazo</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="reasonForDenied"
                                                    onChange={(e) => {
                                                        setReasonForDenied(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="danger" className="mr-2"><CIcon
                                                name="cil-save"/> Rechazar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setDanger(!danger)
                                                setSelectedRequest({})
                                            }}>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={approve}
                                onClose={() => setApprove(!approve)}
                                color="primary"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated">
                                        <CFormGroup>
                                            <CLabel>¿Está seguro que desea aprobar la solicitud?</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="approvalComment">Comentarios (opcional)</CLabel>
                                            <CInput autoComplete="false"
                                                    id="approvalComment"
                                                    onChange={(e) => {
                                                        setApprovalComment(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="button" color="primary" onClick={() => {
                                                setApprove(!approve);
                                                approveRequest();
                                            }}>Sí</CButton>{' '}
                                            <CButton type="button" color="secondary" onClick={() => {
                                                setApprove(!approve)
                                            }}>No</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={12}>
                                            <strong>Lista de solicitudes</strong><br/>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={requests}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay solicitudes', noItems: 'No hay solicitudes'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'show_details':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                hidden={item.approvalStatus !== 'Pendiente'}
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setApprove(!approve)
                                                                    setSelectedRequest(item)
                                                                }}
                                                            >
                                                                Aprobar
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'delete': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            hidden={item.approvalStatus !== 'Pendiente'}
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDanger(!danger);
                                                                setSelectedRequest(item);
                                                            }}
                                                        >
                                                            Rechazar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        )
    }

    useEffect(() => {
        fetchRequests()
    }, []);

    let contents = loading
        ? <p><em>Cargando...</em></p>
        : renderRequestsTable();

    return (contents)
}

export default Requests
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {crudService} from "../../_services/crud.service";
import {
    CContainer,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CDataTable,
    CCard,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle, CLabel
} from '@coreui/react'
import {Role} from "../../_helpers/role";
import Select from "react-select";
import {selectHelper} from "../../_helpers/select.helper";
import AuthProvider from "../../AuthProvider";

const fields = [
    {key: 'number', label: 'Número', _style: {width: '20%'}},
    {key: 'lottery', label: 'Sorteo', _style: {width: '20%'}},
    {key: 'amount', label: 'Monto', _style: {width: '20%'}},
    {key: 'role', label: 'Rol Usuario', _style: {width: '20%'}},
    {key: 'user', label: 'Usuario', _style: {width: '20%'}},
    {
        key: 'show_details',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    },
    {
        key: 'delete',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const Restrictions = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [restrictions, setRestrictions] = useState([]);
    const [danger, setDanger] = useState(false);
    const [selectedRestriction, setSelectedRestriction] = useState({});
    const [lotteries, setLotteries] = useState([]);
    const [selectedLotteryId, setSelectedLotteryId] = useState(null);

    function deleteUser() {
        crudService.remove('/restriction',
            selectedRestriction,
            setSelectedRestriction,
            fetchRestrictions,
            'La restricción se eliminó correctamente.',
            'Ocurrió un error eliminando la restricción.')
    }

    async function fetchRestrictions() {
        if (!selectedLotteryId) {
            setLoading(false);
            setRestrictions([]);
            return;
        }

        await crudService.list(`restriction/getRestrictionsByLotteryId/${selectedLotteryId}`, setRestrictions, setLoading);
    }
    
    useEffect(() => {
        fetchRestrictions()
    }, [selectedLotteryId]);

    useEffect(() => {
        async function fetchLotteries() {
            crudService.get(`lottery/getByCountryId`, (data) => {
                setLotteries(data);
                selectHelper.populateSelectControl(data, "id",
                    "description",
                    setLotteries);
            }, 'Ocurrió un error listando los sorteos.')
        }

        fetchLotteries();
    }, []);

    const renderUsersTable = () => {
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={danger}
                                onClose={() => setDanger(!danger)}
                                color="danger"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    ¿Está seguro que desea eliminar la restricción?
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="danger" onClick={() => {
                                        setDanger(!danger);
                                        deleteUser();
                                    }}>Sí</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setDanger(!danger)
                                        setSelectedRestriction({})
                                    } }>No</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={6}>
                                            <strong>Lista de restricciones</strong><br/>
                                        </CCol>
                                        <CCol lg={6}>
                                            <CButton onClick={() => {
                                                history.push(`/restrictions/create`)
                                            }} type="button" size="md" color="primary" className="mr-2 float-right">+ Crear nuevo restricción</CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg={6}>
                                            <CLabel htmlFor="lottery">Sorteo</CLabel>
                                            <Select
                                                required
                                                value={lotteries.filter(option => option.value === selectedLotteryId)}
                                                onChange={(e) => {
                                                    setSelectedLotteryId(e.value);
                                                }}
                                                placeholder={"Seleccione un sorteo"}
                                                noOptionsMessage={() => "No hay sorteos"}
                                                options={lotteries}/>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={restrictions}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay restricciones para el sorteo seleccionado', noItems: 'No hay restricciones para el sorteo seleccionado'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'show_details':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    history.push(`/restrictions/edit/${item.id}`)
                                                                }}
                                                            >
                                                                Modificar
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'delete': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDanger(!danger);
                                                                setSelectedRestriction(item);
                                                            }}
                                                        >
                                                            Eliminar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        )
    }

    let contents = loading
        ? <p><em>Cargando...</em></p>
        : renderUsersTable();

    return (contents)
}

export default Restrictions
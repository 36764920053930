import React, {useEffect, useState} from 'react'
import Select from 'react-select';
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import {numberHelper} from "../../_helpers/number.helper";
import {selectHelper} from "../../_helpers/select.helper";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const Restriction = ({match}) => {
    const history = useHistory();
    const [existingRestriction, setRestriction] = useState({});
    const [selectedUser, setSelectedUser] = useState("00000000-0000-0000-0000-000000000000");
    const [lotteries, setLotteries] = useState([]);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [numbers, setNumbers] = useState([]);
    const {id} = match.params;
    const isAddMode = !id;
    
    useEffect(() => {
        if (!isAddMode) {
            async function fetchRestriction() {
                crudService.get(`restriction/${id}`, 
                    setRestriction, 
                    'Ocurrió un error obteniendo la restricción');
            }

            fetchRestriction()
        }
    }, []);

    useEffect(() => {
        async function fetchLotteries() {
            crudService.get(`lottery/getByCountryId`, (data) => {
                selectHelper.populateSelectControl(data, "id", "description", setLotteries);
            }, 'Ocurrió un error listando los sorteos.');
        }

        fetchLotteries();
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            const roleId = existingRestriction.roleId ? existingRestriction.roleId : "00000000-0000-0000-0000-000000000000";
            crudService.get(`user/getAllUsers/true/${roleId}`, (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setUsers);
            }, 'Ocurrió un error listando los usuarios.');
        }

        fetchUsers();
    }, [existingRestriction.roleId]);
    
    useEffect(() => {
        async function fetchRoles() {
            crudService.get('role/getAllRoles/true', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setRoles);
            }, 'Ocurrió un error listando los roles.');
        }

        fetchRoles();
    }, []);

    useEffect(() => {
        setNumbers(numberHelper.generateForOptions());
    }, [])

    function updateRestriction() {
        crudService.update('/restriction',
            existingRestriction,
            setRestriction,
            'La restricción se modificó correctamente.',
            'Ocurrió un error modificando la restricción.');
    }

    function createRestriction() {
        crudService.create('/restriction',
            existingRestriction,
            (response) => {
                alertService.success('La restricción se creó correctamente.');
                setRestriction(response.data);
                history.push('/restrictions')
            },
            'Ocurrió un error creando la restricción.')
    }

    const renderRestrictionsForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (!existingRestriction.lotteryId) {
                    alertService.error("El sorteo es requerido.");
                    return;
                }if (existingRestriction.number === undefined) {
                    alertService.error("El número es requerido.");
                    return;
                }
                isAddMode ? createRestriction() : updateRestriction();
            }
        }
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={10}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{isAddMode ? 'Crear restricción' : 'Editar restricción'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel htmlFor="lottery">Sorteo</CLabel>
                                            <Select
                                                required
                                                value={lotteries.filter(option => option.value === existingRestriction.lotteryId)}
                                                onChange={(e) => {
                                                    setRestriction({...existingRestriction, lotteryId: e.value});
                                                }}
                                                placeholder={"Seleccione un sorteo"}
                                                noOptionsMessage={() => "No hay sorteos"}
                                                options={lotteries}/>
                                            <CInvalidFeedback invalid="false">El sorteo es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="role">Rol de usuario</CLabel>
                                            <Select
                                                required
                                                value={roles.filter(option => option.value === existingRestriction.roleId)}
                                                onChange={(e) => {
                                                    setSelectedUser("00000000-0000-0000-0000-000000000000");
                                                    setRestriction({...existingRestriction, roleId: e.value});
                                                }}
                                                placeholder={"Todos"}
                                                noOptionsMessage={() => "No hay roles"}
                                                options={roles}/>
                                            <CInvalidFeedback invalid="false">El rol es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="user">Usuario</CLabel>
                                            <Select
                                                required
                                                value={users.filter(option => option.value === existingRestriction.userId)}
                                                onChange={(e) => {
                                                    setRestriction({...existingRestriction, userId: e.value});
                                                    setSelectedUser(e.value);
                                                }}
                                                placeholder={"Todos"}
                                                noOptionsMessage={() => "No hay usuarios"}
                                                options={users}/>
                                            <CInvalidFeedback invalid="false">El usuario es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="number">Número</CLabel>
                                            <Select
                                                required
                                                value={numbers.filter(option => option.value === existingRestriction.number)}
                                                onChange={(e) => {
                                                    setRestriction({...existingRestriction, number: e.value});
                                                }}
                                                placeholder={"Seleccione un número"}
                                                noOptionsMessage={() => "No hay números"}
                                                options={numbers}/>
                                            <CInvalidFeedback invalid="false">El número es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="close">Monto</CLabel>
                                            <CInput required
                                                    type="number"
                                                    autoComplete="false"
                                                    id="close"
                                                    defaultValue={existingRestriction.amount}
                                                    onChange={(e) => {
                                                        setRestriction({
                                                            ...existingRestriction,
                                                            amount: e.target.value
                                                        });
                                                    }}/>
                                            <CInvalidFeedback invalid="false">El monto es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Guardar</CButton>
                                            <Link to='/restrictions' className="btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderRestrictionsForm())
}

export default Restriction
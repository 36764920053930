import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupText
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import Select from "react-select";
import {hoursHelper} from "../../_helpers/hours.helper";
import {selectHelper} from "../../_helpers/select.helper";
import AuthProvider from "../../AuthProvider";
import {Role} from "../../_helpers/role";

const Lottery = ({match}) => {
    const history = useHistory();
    const [existingLottery, setLottery] = useState({});
    const [hours, setHours] = useState([]);
    const [selectedHour, setSelectedHour] = useState({});
    const { id } = match.params;
    const isAddMode = !id;

    useEffect(() => {
        setHours(hoursHelper.generateForOptions());
    }, [])
    
    useEffect(() => {
        if (!isAddMode) {
            async function fetchLottery() {
                const response = await fetch(`lottery/${id}`);
                const data = await response.json();
                setLottery(data);
                setSelectedHour({...selectedHour, value: data.close});
            }

            fetchLottery()
        }
    }, []);
    
    function updateLottery(){
        crudService.update('/lottery',
            existingLottery,
            (response) => {
                alertService.success('El sorteo se creó correctamente.');
                history.push('/lotteries')
            },
            'El sorteo se modificó correctamente.',
            'Ocurrió un error modificando el sorteo.');
    }

    function createLottery() {
        crudService.create('/lottery',
            existingLottery,
            (response) => {
                alertService.success('El sorteo se creó correctamente.');
                history.push('/lotteries')
            },
            'Ocurrió un error creando el sorteo.')
    }

    const renderLotteriesForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (existingLottery.close === undefined) {
                    alertService.error("La hora de cierre es requerida.");
                    return;
                }
                isAddMode ? createLottery() : updateLottery();
            }
        }
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={10}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{isAddMode ? 'Crear sorteo' : 'Editar sorteo'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel htmlFor="description">Descripción</CLabel>
                                            <CInput type="text"
                                                    autoComplete="false"
                                                    required
                                                    id="description"
                                                    defaultValue={existingLottery.description}
                                                    onChange={(e) => {
                                                        setLottery({...existingLottery, description: e.target.value});
                                                    }}
                                            />
                                            <CInvalidFeedback>La descripción es requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="prize">Premio</CLabel>
                                            <CInput
                                                type="number"
                                                required
                                                autoComplete="false"
                                                id="prize"
                                                defaultValue={existingLottery.prize}
                                                onChange={(e) => {
                                                    setLottery({...existingLottery, prize: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El premio es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="bucket">Fondos proyecto</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInput
                                                    type="number"
                                                    required
                                                    autoComplete="false"
                                                    id="bucket"
                                                    defaultValue={existingLottery.bucket}
                                                    onChange={(e) => {
                                                        setLottery({...existingLottery, bucket: e.target.value});
                                                    }}/>
                                                <CInputGroupAppend>
                                                    <CInputGroupText>%</CInputGroupText>
                                                </CInputGroupAppend>
                                                <CInvalidFeedback invalid="false">Los fondos del proyecto es requerido.</CInvalidFeedback>
                                            </CInputGroup>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="prizePool">Acumulado</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInput
                                                    type="number"
                                                    required
                                                    autoComplete="false"
                                                    id="prizePool"
                                                    defaultValue={existingLottery.prizePool}
                                                    onChange={(e) => {
                                                        setLottery({...existingLottery, prizePool: e.target.value});
                                                    }}/>
                                                <CInputGroupAppend>
                                                    <CInputGroupText>%</CInputGroupText>
                                                </CInputGroupAppend>
                                                <CInvalidFeedback invalid="false">El acumulado es requerido.</CInvalidFeedback>
                                            </CInputGroup>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="close">Hora de Cierre</CLabel>
                                            <Select
                                                required
                                                value={{label: hoursHelper.toAMPM(existingLottery.close), value: existingLottery.close}}
                                                onChange={(e) => {
                                                    setLottery({...existingLottery, close: e.value});
                                                    setSelectedHour(e)
                                                }}
                                                placeholder={"Seleccione una hora"}
                                                noOptionsMessage={() => "No hay horas"}
                                                options={hours}/>
                                            <CInvalidFeedback invalid="false">La hora de cierre es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Guardar</CButton>
                                            <Link to='/lotteries' className="btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderLotteriesForm(existingLottery))
}

export default Lottery

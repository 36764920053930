import React, {useEffect, useState} from "react";
import {crudService} from "../_services/crud.service";

const NumberTable = ({sales}) => {
    const [countryCurrencySign, setCountryCurrencySign] = useState(null);
    let numbers = [];
    
    for(let i = 0; i < sales.length; i++) {
        numbers = [].concat(numbers, sales[i].numbers);
    }
    
    let firstFooter = 0;
    let secondFooter = 0;
    let thirdFooter = 0;
    let fourthFooter = 0;
    let fifthFooter = 0;
    let sixthFooter = 0;
    const findAmount = (number) => {
        if (!numbers.length) {
            return 0;
        }
        const found = numbers.filter(sale => sale.number === number);
        let amount = 0;
        for(let i = 0; i < found.length; i++) {
            amount += found[i].amount;
        }
        return amount;
    }

    useEffect(() => {
        async function fetchCountryCurrencySign() {
            crudService.get('User/getCountryCurrencySign', (currencySign) => {
                setCountryCurrencySign(currencySign);
            }, 'Ocurrió un error obteniendo el símbolo de la moneda.')
        }

        fetchCountryCurrencySign();
    }, []);
    
    const renderTable = () => {
        const rows = [];
        
        for(let i = 0; i < 17; i++) {
            const firstTd = i;
            const firstAmount = findAmount(firstTd);
            firstFooter += firstAmount;
            const secondTd = i + 17;
            const secondAmount = findAmount(secondTd);
            secondFooter += secondAmount;
            const thirdTd = i + 34;
            const thirdAmount = findAmount(thirdTd);
            thirdFooter += thirdAmount;
            const fourthTd = i + 51;
            const fourthAmount = findAmount(fourthTd);
            fourthFooter += fourthAmount;
            const fifthTd = i + 68;
            const fifthAmount = findAmount(fifthTd);
            fifthFooter += fifthAmount;
            const sixthTd = i + 85;
            const sixthAmount = findAmount(sixthTd);
            sixthFooter += sixthAmount;
            
            rows.push(
            <tr key={i.toString()}>
                <td>{firstTd}</td>
                <td>{countryCurrencySign}{firstAmount}</td>
                <td>{secondTd}</td>
                <td>{countryCurrencySign}{secondAmount}</td>
                <td>{thirdTd}</td>
                <td>{countryCurrencySign}{thirdAmount}</td>
                <td>{fourthTd}</td>
                <td>{countryCurrencySign}{fourthAmount}</td>
                <td>{fifthTd}</td>
                <td>{countryCurrencySign}{fifthAmount}</td>
                {sixthTd >= 100 ? <td/> : <td>{sixthTd}</td>}
                {sixthTd >= 100 ? <td/> : <td>{countryCurrencySign}{sixthAmount}</td>}
            </tr>)
        }
        return (rows);
    }
    
    const renderTableHeader = () => {
        return (
            <tr>
                <th className="vertical-align: middle; overflow: hidden;">Num</th>
                <th className="vertical-align: middle; overflow: hidden;">Monto</th>
                <th className="vertical-align: middle; overflow: hidden;">Num</th>
                <th className="vertical-align: middle; overflow: hidden;">Monto</th>
                <th className="vertical-align: middle; overflow: hidden;">Num</th>
                <th className="vertical-align: middle; overflow: hidden;">Monto</th>
                <th className="vertical-align: middle; overflow: hidden;">Num</th>
                <th className="vertical-align: middle; overflow: hidden;">Monto</th>
                <th className="vertical-align: middle; overflow: hidden;">Num</th>
                <th className="vertical-align: middle; overflow: hidden;">Monto</th>
                <th className="vertical-align: middle; overflow: hidden;">Num</th>
                <th className="vertical-align: middle; overflow: hidden;">Monto</th>
            </tr>
        )
    }
    
    const renderTableFooter = () => {
        return (
            <tr>
                <th className="vertical-align: middle; overflow: hidden;"/>
                <th className="vertical-align: middle; overflow: hidden;">{countryCurrencySign}{firstFooter}</th>
                <th className="vertical-align: middle; overflow: hidden;"/>
                <th className="vertical-align: middle; overflow: hidden;">{countryCurrencySign}{secondFooter}</th>
                <th className="vertical-align: middle; overflow: hidden;"/>
                <th className="vertical-align: middle; overflow: hidden;">{countryCurrencySign}{thirdFooter}</th>
                <th className="vertical-align: middle; overflow: hidden;"/>
                <th className="vertical-align: middle; overflow: hidden;">{countryCurrencySign}{fourthFooter}</th>
                <th className="vertical-align: middle; overflow: hidden;"/>
                <th className="vertical-align: middle; overflow: hidden;">{countryCurrencySign}{fifthFooter}</th>
                <th className="vertical-align: middle; overflow: hidden;"/>
                <th className="vertical-align: middle; overflow: hidden;">{countryCurrencySign}{sixthFooter}</th>
            </tr>
        )
    }
    
    return (<table className="table">
                <thead>{renderTableHeader()}</thead>
                <tbody>
                    {renderTable()}
                </tbody>
                <tfoot>{renderTableFooter()}</tfoot>
            </table>);
}

export default NumberTable;
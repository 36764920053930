import React, {useEffect, useState, useRef} from 'react'
import {HubConnectionBuilder} from '@microsoft/signalr';
import {useSelector, useDispatch} from 'react-redux';
import AuthProvider from "../AuthProvider";
import {
    CHeader,
    CToggler,
    CHeaderBrand,
    CHeaderNav,
    CHeaderNavItem,
    CSubheader,
    CImg,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CCardFooter,
    CButton, CInputGroupPrepend, CInputGroupText, CInputGroup, CInvalidFeedback, CInputCheckbox, CRow, CCol
} from '@coreui/react'
import {authenticationService} from "../_services/authentication.service";

import {
    TheHeaderDropdown
} from './index'
import {crudService} from "../_services/crud.service";
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
import TheConfigMenu from "./TheConfigMenu";
import TheDonationMenu from "./TheDonationMenu";
import {Role} from "../_helpers/role";
import CIcon from "@coreui/icons-react";
import {alertService} from "../_services/alert.service";
import {selectHelper} from "../_helpers/select.helper";
import Select from "react-select";
import {Col} from "reactstrap";

const TheHeader = () => {
    const [countryCurrencySign, setCountryCurrencySign] = useState(null);
    const [countryFlag, setCountryFlag] = useState(null);
    const [userPhoto, setUserPhoto] = useState(null);
    const [balance, setBalance] = useState(0.00);
    const [connection, setConnection] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const latestNotification = useRef(null);
    const sidebarShow = useSelector(state => state.sidebarShow);
    const dispatch = useDispatch();
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [amountRequested, setAmountRequested] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");

    latestNotification.current = notifications;

    const toggleSidebar = () => {
        const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
        dispatch({
            type: 'set',
            sidebarShow: val
        });
    };

    const toggleSidebarMobile = () => {
        const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
        dispatch({
            type: 'set',
            sidebarShow: val
        });
    }

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('/hubs/notification', {
                accessTokenFactory: () => {
                    return authenticationService.token;
                }
            })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('SendNotification', message => {
                        const updatedNotification = [...latestNotification.current];
                        updatedNotification.push(message);

                        setNotifications(updatedNotification);
                    });
                })
                .catch(e => {
                    // ignored
                });
        }
    }, [connection]);

    useEffect(() => {
        async function fetchCountryFlag() {
            crudService.get('User/getCountryFlag', (data) => {
                setCountryFlag(`data:image/png;base64,${data}`)
            }, 'Ocurrió un error obteniendo la bandera.')
        }

        fetchCountryFlag();
    }, []);

    useEffect(() => {
        async function fetchCountryCurrencySign() {
            crudService.get('User/getCountryCurrencySign', (currencySign) => {
                setCountryCurrencySign(currencySign);
            }, 'Ocurrió un error obteniendo el símbolo de la moneda.')
        }

        fetchCountryCurrencySign();
    }, []);

    useEffect(() => {
        async function R() {
            if (Notification.permission === 'default' || Notification.permission === 'denied') {
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                    console.log("Permission granted");
                }
            } else {
                console.log("Permission previously granted");
            }
        }

        R();
    }, []);

    useEffect(() => {
        async function fetchUserPhoto() {
            crudService.get('User/getPhoto', (data) => {
                if (!data) {
                    return;
                }
                setUserPhoto(`data:image/png;base64,${data}`)
            }, 'Ocurrió un error obteniendo la foto.')
        }

        fetchUserPhoto();
    }, []);

    useEffect(() => {
        async function fetchBalance() {
            crudService.get('User/getCurrentUserBalance', (data) => {
                setBalance(data);
            }, 'Ocurrió un error obteniendo el balance.')
        }

        fetchBalance();
    }, []);

    useEffect(() => {
        async function fetchPaymentMethods() {
            crudService.get('/country/getCurrentCountryPaymentMethods', (data) => {
                selectHelper.populateSelectControl(data, "id", "paymentMethodName", setPaymentMethods);
            }, 'Ocurrió un error listando los métodos de pago.');
        }

        fetchPaymentMethods();
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity()) {
            if (amountRequested <= 0) {
                alertService.error('El monto ingresado debe ser mayor a 0.');
                return;
            }

            crudService.post("request", {
                amountRequested: amountRequested,
                paymentMethodId: selectedPaymentMethod,
                accountNumber: accountNumber
            }, () => {
                setShowRequestModal(!showRequestModal);
                setAmountRequested(0);
                setSelectedPaymentMethod(null);
                setAccountNumber("");
                alertService.success("Solicitud creada. Pendiente de aprobación.");
            }, "Ocurrió un error creando la solicitud de acreditación de saldo.");
        }
    }

    return (
        <>
            <CHeader className="header d-md-down-none" withSubheader>
                <CToggler
                    inHeader
                    className="ml-md-3 d-lg-none"
                    onClick={toggleSidebarMobile}
                />
                <CToggler
                    inHeader
                    className="ml-3 d-md-down-none"
                    onClick={toggleSidebar}
                />
                <CHeaderBrand to="/">
                    <CImg style={{"height": 62, "marginBottom": "0.3rem", "marginTop": "0.3rem"}}
                          src={require("../assets/images/logo.png").default} fluid></CImg>
                </CHeaderBrand>

                <CHeaderNav className="px-3 mr-auto">
                    <CHeaderNavItem className="px-3">
                        <CRow>
                            <CCol>
                                <div style={{width: "7rem"}}>
                                    <CImg src={countryFlag} fluid className="img-thumbnail flag"></CImg>
                                </div>
                            </CCol>
                            <CCol>
                                <div style={{width: "9rem"}}>
                                    <CImg
                                        src={userPhoto ? userPhoto : require("../assets/images/defaultUserProfile.png").default}
                                        fluid className="img-thumbnail"></CImg>
                                </div>
                            </CCol>
                            <CCol>
                                <div>
                                    <label className="bold-header-text">
                                        {authenticationService.currentUserValue.RoleName}
                                    </label>
                                </div>
                            </CCol>
                            <CCol>
                                <div>
                                    <label className="bold-header-text">
                                        {authenticationService.currentUserValue.family_name}
                                    </label>
                                    <br/>
                                    <label className="bold-header-text">
                                        # {authenticationService.currentUserValue.Dni}
                                    </label>
                                </div>
                            </CCol>
                        </CRow>
                    </CHeaderNavItem>
                </CHeaderNav>
                <CHeaderNav className="px-3">
                    <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                        <TheConfigMenu/>
                    </AuthProvider>
                </CHeaderNav>
                <CHeaderNav className="px-3">
                    <AuthProvider>
                        <TheDonationMenu/>
                    </AuthProvider>
                </CHeaderNav>
                <CHeaderNav className="px-4">
                    <CHeaderNavItem>
                        <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                            <div>
                                <label className="bold-header-text">
                                    SALDO
                                </label>
                                <br/>
                                <label className="bold-header-text">
                                    {countryCurrencySign} {balance}
                                </label>
                            </div>
                        </AuthProvider>
                    </CHeaderNavItem>
                    <CHeaderNavItem>
                        <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                            <a className="btn btn-primary" style={{"marginLeft": "1rem"}}
                               href="#/recharges">Recargar</a>
                        </AuthProvider>
                    </CHeaderNavItem>
                    <CHeaderNavItem>
                        <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                            <button className="btn btn-primary" style={{"marginLeft": "1rem"}} onClick={() => {
                                setShowRequestModal(!showRequestModal)
                            }}>Acreditar
                            </button>
                            <CModal
                                show={showRequestModal}
                                onClose={() => setShowRequestModal(!showRequestModal)}
                                color="danger">
                                <CModalHeader closeButton>
                                    <CModalTitle>Crear solicitud para acreditar saldo</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel>El monto ingresado debe ser menor o igual al saldo actual.</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="paymentMethod">Método de pago</CLabel>
                                            <Select
                                                required
                                                onChange={(e) => {
                                                    setSelectedPaymentMethod(e.value);
                                                }}
                                                id="paymentMethod"
                                                placeholder={"Seleccione un método de pago"}
                                                noOptionsMessage={() => "No hay métodos de pago para este país."}
                                                options={paymentMethods}/>
                                            <CInvalidFeedback>El método de pago es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="accountNumber">Ingrese el número de cuenta
                                                correspondiente</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="accountNumber"
                                                    type="text"
                                                    value={accountNumber}
                                                    onChange={(e) => {
                                                        setAccountNumber(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="amountRequested">Ingrese el saldo a acreditar</CLabel>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        {countryCurrencySign}
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput required
                                                        autoComplete="false"
                                                        id="amountRequested"
                                                        type="number"
                                                        value={amountRequested}
                                                        onChange={(e) => {
                                                            setAmountRequested(e.target.value);
                                                        }}/>
                                            </CInputGroup>
                                        </CFormGroup>
                                        <CFormGroup variant="custom-checkbox" className="my-2 mt-4">
                                            <CLabel htmlFor="termsAndConditionsLabel">Está transacción podría tener
                                                cargos adicionales dependiendo el tipo de transacción que se realice y
                                                la comisión que puedan cobrar los bancos. </CLabel>
                                            <CInputCheckbox
                                                id="termsAndConditions"
                                                custom
                                                required
                                            />
                                            <CLabel variant="custom-checkbox" htmlFor="termsAndConditions">
                                                Estoy de acuerdo
                                            </CLabel>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-scrubber"/> Solicitar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setShowRequestModal(!showRequestModal)
                                            }}>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </AuthProvider>
                    </CHeaderNavItem>
                    <CHeaderNavItem>
                        <TheHeaderDropdownNotif notifications={notifications} customClassName="nothing"/>
                    </CHeaderNavItem>
                </CHeaderNav>
                <CHeaderNav className="px-3 float-right">
                    <CHeaderNavItem>
                        <a className="logout" href="#" onClick={() => {
                            authenticationService.logout()
                        }}>Cerrar sesión</a>
                    </CHeaderNavItem>
                </CHeaderNav>
            </CHeader>
            <CHeader className="header d-lg-none" withSubheader>
                <CToggler
                    inHeader
                    className="ml-md-3 d-lg-none"
                    onClick={toggleSidebarMobile}
                />
                <CToggler
                    inHeader
                    className="ml-3 d-md-down-none"
                    onClick={toggleSidebar}
                />

                <CHeaderNav className="px-3 mr-auto">
                    <CHeaderNavItem className="px-3">
                        <CRow>
                            <CCol>
                                <CImg style={{"height": 32, "marginBottom": "0.3rem", "marginTop": "0.3rem"}}
                                      src={require("../assets/images/logo.png").default} fluid></CImg>
                            </CCol>
                            <CCol>
                                <div style={{width: "7rem"}}>
                                    <CImg src={countryFlag} fluid className="img-thumbnail flag"></CImg>
                                </div>
                            </CCol>
                            <CCol>
                                <div style={{width: "7rem"}}>
                                    <CImg
                                        src={userPhoto ? userPhoto : require("../assets/images/defaultUserProfile.png").default}
                                        fluid className="img-thumbnail"></CImg>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <div>
                                    <label className="bold-header-text-mobile">
                                        {authenticationService.currentUserValue.family_name} |
                                        #{authenticationService.currentUserValue.Dni} |
                                        SALDO {countryCurrencySign} {balance}
                                    </label>
                                </div>
                            </CCol>
                            <CCol>
                                <div>
                                    <TheHeaderDropdownNotif notifications={notifications} customClassName="mobile-notif"/>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <hr className="horizontal-line"/>
                            </CCol>
                        </CRow>
                    </CHeaderNavItem>
                </CHeaderNav>
                <CHeaderNav className="px-3 mr-auto">
                    <CHeaderNavItem className="px-3">
                        <CRow>
                            <CCol className="mr-4">
                                <AuthProvider
                                    roles={[Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                                    <TheConfigMenu></TheConfigMenu>
                                </AuthProvider>
                            </CCol>
                            <CCol>
                                <AuthProvider>
                                    <TheDonationMenu ></TheDonationMenu>
                                </AuthProvider>
                            </CCol>
                            <CCol>
                                <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                                    <a className="btn btn-primary" href="#/recharges">Recargar</a>
                                </AuthProvider>
                            </CCol>
                            <CCol>
                                <AuthProvider roles={[Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                                    <button className="btn btn-primary" onClick={() => {
                                        setShowRequestModal(!showRequestModal)
                                    }}>Acreditar
                                    </button>
                                    <CModal
                                        show={showRequestModal}
                                        onClose={() => setShowRequestModal(!showRequestModal)}
                                        color="danger">
                                        <CModalHeader closeButton>
                                            <CModalTitle>Crear solicitud para acreditar saldo</CModalTitle>
                                        </CModalHeader>
                                        <CModalBody>
                                            <CForm className="was-validated"
                                                   onSubmit={handleSubmit}>
                                                <CFormGroup>
                                                    <CLabel>El monto ingresado debe ser menor o igual al saldo
                                                        actual.</CLabel>
                                                </CFormGroup>
                                                <CFormGroup>
                                                    <CLabel htmlFor="paymentMethod">Método de pago</CLabel>
                                                    <Select
                                                        required
                                                        onChange={(e) => {
                                                            setSelectedPaymentMethod(e.value);
                                                        }}
                                                        id="paymentMethod"
                                                        placeholder={"Seleccione un método de pago"}
                                                        noOptionsMessage={() => "No hay métodos de pago para este país."}
                                                        options={paymentMethods}/>
                                                    <CInvalidFeedback>El método de pago es requerido.</CInvalidFeedback>
                                                </CFormGroup>
                                                <CFormGroup>
                                                    <CLabel htmlFor="accountNumber">Ingrese el número de cuenta
                                                        correspondiente</CLabel>
                                                    <CInput required
                                                            autoComplete="false"
                                                            id="accountNumber"
                                                            type="text"
                                                            value={accountNumber}
                                                            onChange={(e) => {
                                                                setAccountNumber(e.target.value);
                                                            }}/>
                                                </CFormGroup>
                                                <CFormGroup>
                                                    <CLabel htmlFor="amountRequested">Ingrese el saldo a
                                                        acreditar</CLabel>
                                                    <CInputGroup className="mb-3">
                                                        <CInputGroupPrepend>
                                                            <CInputGroupText>
                                                                {countryCurrencySign}
                                                            </CInputGroupText>
                                                        </CInputGroupPrepend>
                                                        <CInput required
                                                                autoComplete="false"
                                                                id="amountRequested"
                                                                type="number"
                                                                value={amountRequested}
                                                                onChange={(e) => {
                                                                    setAmountRequested(e.target.value);
                                                                }}/>
                                                    </CInputGroup>
                                                </CFormGroup>
                                                <CFormGroup variant="custom-checkbox" className="my-2 mt-4">
                                                    <CLabel htmlFor="termsAndConditionsLabel">Está transacción podría
                                                        tener cargos adicionales dependiendo el tipo de transacción que
                                                        se realice y la comisión que puedan cobrar los bancos. </CLabel>
                                                    <CInputCheckbox
                                                        id="termsAndConditions"
                                                        custom
                                                        required
                                                    />
                                                    <CLabel variant="custom-checkbox" htmlFor="termsAndConditions">
                                                        Estoy de acuerdo
                                                    </CLabel>
                                                </CFormGroup>
                                                <CCardFooter>
                                                    <CButton type="submit" size="md" color="primary"
                                                             className="mr-2"><CIcon
                                                        name="cil-scrubber"/> Solicitar</CButton>
                                                    <CButton color="secondary" onClick={() => {
                                                        setShowRequestModal(!showRequestModal)
                                                    }}>Cancelar</CButton>
                                                </CCardFooter>
                                            </CForm>
                                        </CModalBody>
                                    </CModal>
                                </AuthProvider>
                            </CCol>
                        </CRow>
                    </CHeaderNavItem>
                </CHeaderNav>
            </CHeader>
        </>
    )
}

export default TheHeader
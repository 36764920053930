import React from 'react'
import ReactDOM from 'react-dom'
import Alert from './alert';

export const alertService = {
    success,
    error,
    info
};

function success(message) {
    alert({ className: 'text-white bg-success', text: message, show: true});
}

function error(message) {
    alert({ className: 'text-white bg-danger', text: message, show: true});
}

function info(message) {
    alert({ className: 'text-white bg-info', text: message, show: true});
}

// core alert method
function alert(props) {
    cleanup();
    const div = document.createElement('div');
    div.id = "alertdiv";
    ReactDOM.render(
        <Alert {...props}/>,
        document.body.appendChild(div)
    );
}

function cleanup() {
    const element = document.querySelector('#alertdiv');
    if (element) {
        element.parentNode.removeChild(element);   
    }
}
import React, {useEffect, useState} from 'react'
import es from 'date-fns/locale/es';
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import Select from "react-select";
import {authenticationService} from "../../_services/authentication.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {selectHelper} from "../../_helpers/select.helper";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";
import ImageUploading from 'react-images-uploading';

const User = ({match}) => {
    const history = useHistory();
    const [existingUser, setUser] = useState({
        projectIds: []
    });
    const [countries, setCountries] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [images, setImages] = useState([]);
    const maxNumber = 1;
    const {id} = match.params;
    const isAddMode = !id;
    const disabled = id !== authenticationService.getUserId;

    const onImageChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        setUser({...existingUser, photoData: imageList.length > 0 ? imageList[0].data_url : ""});
    };

    useEffect(() => {
        async function fetchUser() {
            crudService.get(`user/${id}`, (data) => {
                setUser(data);
                if (data.photo) {
                    setImages([{data_url: `data:image/jpeg;base64,${data.photo}`}]);
                }
            }, 'Ocurrió un error obteniendo el usuario')
        }

        fetchUser()
    }, []);

    useEffect(() => {
        async function fetchCountries() {
            crudService.get('/country/getCurrentUserCountry', (data) => {
                selectHelper.populateSelectControl([data], "id", "name", setCountries);
            }, 'Ocurrió un error listando los paises.');
        }

        fetchCountries();
    }, [existingUser.countryId]);

    useEffect(() => {
        async function fetchPaymentMethods() {
            if (!existingUser.countryId) {
                return;
            }
            crudService.get(`/country/getCountryPaymentMethods/${existingUser.countryId}`, (data) => {
                // Create a set of all ids in the first array
                let idsInFirstArray = new Set(existingUser.paymentMethods.map(item => item.paymentMethodId));
                let itemsNotInFirstArray = data.filter(item => !idsInFirstArray.has(item.id));
                let newItems = itemsNotInFirstArray.map(item => ({
                    paymentMethodId: item.id,
                    userId: "", // Assuming the userId is unknown
                    value: "", // Assuming the value is unknown
                    paymentMethodName: item.paymentMethodName,
                    id: item.id // Assuming the id is the same as paymentMethodId
                }));
                
                let resultArray = existingUser.paymentMethods.concat(newItems);
                setPaymentMethods(resultArray);
            }, 'Ocurrió un error listando los métodos de pago.');
        }

        fetchPaymentMethods();
    }, [existingUser.countryId]);

    function updateUser() {
        if (typeof existingUser.birth === 'string') {
            existingUser.birth = new Date(existingUser.birth);
        }
        existingUser.yearBirth = existingUser.birth.getFullYear();
        existingUser.monthBirth = existingUser.birth.getMonth() + 1;
        existingUser.dayBirth = existingUser.birth.getDate();
        crudService.update('/user', existingUser,
            (data) => {
            },
            'El usuario se modificó correctamente.',
            'Ocurrió un error modificando el usuario.');
    }

    const renderUserForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (authenticationService.isManager && existingUser.projectIds.length === 0) {
                    alertService.error("El proyecto es requerido.");
                    return;
                }
                if (!existingUser.countryId) {
                    alertService.error("El país es requerido.");
                    return;
                }
                if (!existingUser.roleId) {
                    alertService.error("El rol es requerido.");
                    return;
                }
                if (!existingUser.bossId) {
                    alertService.error("El encargado es requerido.");
                    return;
                }
                if (!existingUser.birth) {
                    alertService.error("La fecha de nacimiento es requerida.");
                    return;
                }
                if (!existingUser.paymentMethods || existingUser.paymentMethods.length === 0) {
                    alertService.error("La información bancaria es requerida.");
                    return;
                }
                updateUser();
            }
        }
        return (
            <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={6}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Editar usuario'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel htmlFor="birth">Fecha de nacimiento</CLabel>
                                            <DatePicker
                                                id="birth"
                                                dateFormat="dd/MM/yyyy"
                                                selected={existingUser.birth ? new Date(existingUser.birth) : null}
                                                autoComplete="false"
                                                required
                                                locale={es}
                                                onChange={(date) => setUser({...existingUser, birth: date})}
                                                className="form-control"
                                            />
                                            <CInvalidFeedback invalid="false">La fecha de nacimiento es
                                                requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="address">Dirección</CLabel>
                                            <CInput
                                                required
                                                autoComplete="false"
                                                id="address"
                                                defaultValue={existingUser.address}
                                                onChange={(e) => {
                                                    setUser({...existingUser, address: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">La dirección es
                                                requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="email">Email</CLabel>
                                            <CInput
                                                required
                                                autoComplete="false"
                                                id="email"
                                                defaultValue={existingUser.email}
                                                onChange={(e) => {
                                                    setUser({...existingUser, email: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El correo electrónico es
                                                requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phone">Teléfono</CLabel>
                                            <CInput
                                                required
                                                autoComplete="false"
                                                id="phone"
                                                defaultValue={existingUser.phone}
                                                onChange={(e) => {
                                                    setUser({...existingUser, phone: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El teléfono es
                                                requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phone2">Teléfono 2</CLabel>
                                            <CInput
                                                autoComplete="false"
                                                id="phone2"
                                                defaultValue={existingUser.phone2}
                                                onChange={(e) => {
                                                    setUser({...existingUser, phone2: e.target.value});
                                                }}/>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="password">Contraseña</CLabel>
                                            <CInput required
                                                    disabled={true}
                                                    autoComplete="false"
                                                    id="password"
                                                    type="password"
                                                    defaultValue={existingUser.password}
                                                    onChange={(e) => {
                                                        setUser({...existingUser, password: e.target.value});
                                                    }}/>
                                            <CInvalidFeedback invalid="false">La contraseña es
                                                requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" disabled={disabled} size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Guardar</CButton>
                                            <Link to='/users' className="btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={6}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Información bancaria'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm onSubmit={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}>
                                        <CFormGroup>
                                            <CLabel htmlFor="countryCheck" style={{marginRight: "1.5%"}}>País:</CLabel>
                                            <Select
                                                isDisabled={!isAddMode}
                                                required
                                                value={countries.filter(option => option.value === existingUser.countryId)}
                                                onChange={(e) => {
                                                    setUser({...existingUser, countryId: e.value});
                                                }}
                                                placeholder={"Seleccione un país"}
                                                noOptionsMessage={() => "No hay paises"}
                                                options={countries}/>
                                        </CFormGroup>
                                        {
                                            paymentMethods.map((paymentMethod, i) => {
                                                return <CFormGroup key={paymentMethod.id}>
                                                    <CLabel>{paymentMethod.paymentMethodName}</CLabel>
                                                    <CInput
                                                        autoComplete="false"
                                                        value={paymentMethod.value}
                                                        onChange={(e) => {
                                                            const paymentMethods = existingUser.paymentMethods || [];
                                                            let payM = paymentMethods.find(value => value.paymentMethodId === e.target.id);
                                                            if (!payM) {
                                                                payM = {
                                                                    paymentMethodId: e.target.id,
                                                                    paymentMethodName: paymentMethod.paymentMethodName,
                                                                    value: e.target.value,
                                                                    userId: existingUser.id
                                                                };
                                                                paymentMethods.push(payM);
                                                            } else {
                                                                payM.paymentMethodId = e.target.id;
                                                                payM.value = e.target.value;
                                                                payM.userId = existingUser.id;
                                                                payM.paymentMethodName = paymentMethod.paymentMethodName;
                                                            }
                                                            setUser({...existingUser, paymentMethods: paymentMethods});
                                                            setPaymentMethods([...paymentMethods]);
                                                        }}
                                                        id={paymentMethod.paymentMethodId || paymentMethod.id}/>
                                                </CFormGroup>
                                            })
                                        }
                                        <CCardFooter>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Fotografía de perfil'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <ImageUploading
                                        value={images}
                                        onChange={onImageChange}
                                        maxNumber={maxNumber}
                                        acceptType={['jpg', 'gif', 'png']}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                              imageList,
                                              onImageUpload,
                                              onImageRemoveAll,
                                              onImageUpdate,
                                              onImageRemove,
                                              isDragging,
                                              dragProps,
                                              errors
                                          }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">
                                                {errors && <div>
                                                {errors.acceptType && <span>El archivo seleccionado no es válido</span>}
                                                </div>}
                                                <button
                                                    style={isDragging ? { color: 'red' } : undefined}
                                                    hidden={imageList.length > 0}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Agregar fotografía
                                                </button>
                                                &nbsp;
                                                <button onClick={onImageRemoveAll}>Remover fotografía</button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <div className="image-container">
                                                            <img src={image['data_url']} alt="" />
                                                        </div>
                                                        <div className="image-item__btn-wrapper">

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderUserForm(existingUser))
}

export default User

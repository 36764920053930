import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import TheLayout from './containers/TheLayout';
import Login from './views/pages/login/Login';
import { PrivateRoute } from './components/PrivateRoute';
import ForgotPassword from "./views/pages/login/ForgotPassword";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)

class App extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login" render={props => <Login {...props}/>}/>
                        <Route exact path="/forgot" name="ForgotPassword" render={props => <ForgotPassword {...props}/>}/>
                        <PrivateRoute path="/" component={TheLayout} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;

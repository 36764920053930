import React, {useEffect, useState} from 'react'
import Select from 'react-select';
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback, CTextarea
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import {numberHelper} from "../../_helpers/number.helper";
import {selectHelper} from "../../_helpers/select.helper";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";
import {hi} from "date-fns/locale";
import {authenticationService} from "../../_services/authentication.service";

const Chat = ({match}) => {
    const history = useHistory();
    const [notif, setNotif] = useState({});
    const [users, setUsers] = useState([]);
    const {id} = match.params;

    useEffect(() => {
        async function fetchUsers() {
            const roleId = "00000000-0000-0000-0000-000000000000";
            crudService.get(`user/getAllUsers/true/${roleId}`, (data) => {
                selectHelper.populateSelectControl(data, "id", "fullName", setUsers);
            }, 'Ocurrió un error listando los usuarios.');
        }

        fetchUsers();
    }, []);
    

    const renderChatForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                crudService.post('/chat/send',
                    notif,
                    () => {
                        //history.push('/');
                    },
                    'Se envió el mensaje.',
                    'Ocurrió un error enviando el mensaje.');
            }
        }
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={10}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Enviar mensaje'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel htmlFor="user">Usuario</CLabel>
                                            <Select
                                                required
                                                onChange={(e) => {
                                                    setNotif({...notif, recipientId: e.value});
                                                }}
                                                placeholder={"Todos"}
                                                noOptionsMessage={() => "No hay usuarios"}
                                                options={users}/>
                                            <CInvalidFeedback invalid="false">El usuario es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="title">Titulo</CLabel>
                                            <CInput
                                                type="text"
                                                required
                                                autoComplete="false"
                                                id="title"
                                                onChange={(e) => {
                                                    setNotif({...notif, title: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El titulo es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="subtitle">Subtitulo</CLabel>
                                            <CInput
                                                type="text"
                                                autoComplete="false"
                                                id="subtitle"
                                                onChange={(e) => {
                                                    setNotif({...notif, subtitle: e.target.value});
                                                }}/>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="comment">Notificación</CLabel>
                                            <CTextarea
                                                rows="4"
                                                id="comment"
                                                placeholder="Comentarios"
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    setNotif({...notif, message: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El comentario es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Enviar</CButton>
                                            <Link to='/' className="btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderChatForm())
}

export default Chat
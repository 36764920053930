import axios from 'axios';
import { handleResponse } from '../_helpers/handle-response';
import {Role} from "../_helpers/role";
import {alertService} from "./alert.service";
import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';

export const authenticationService = {
    login,
    logout,
    generateHmac,
    get currentUserValue () { return parseJwt(localStorage.getItem('currentUser')) },
    get token () {return localStorage.getItem('currentUser')},
    get isManager () { 
        const user = this.currentUserValue;
        return user.role === Role.Manager
    },
    get isDonor() {
        const user = this.currentUserValue;
        return user.role === Role.Donor
    },
    sameAsCurrentUser(userId) {
        const user = this.currentUserValue;
        return user.unique_name === userId;
    },
    get getUserId () { return this.currentUserValue.nameid },
    get getUserEmail () { return this.currentUserValue.Email },
    get getTzName () { return this.currentUserValue.TzName },
    get getUserFullName () {return this.currentUserValue.family_name},
    get getUserPhoneNumber () {return this.currentUserValue.PhoneNumber},
    get getCountry () {return this.currentUserValue.CountryId},
    get isPeru () { return this.currentUserValue.CountryId === "0dc96921-c6f5-485f-8f6b-3bf8a0c58bfc" },
    get isCr () { return this.currentUserValue.CountryId === "37b9172b-d20e-4050-bfc2-7bbaaa949b27" },
};

function parseJwt (token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    catch {
        return '';
    }
}

function login(username, password) {
    return axios.post('/user/login', { Username: username, Password: password }).then(handleResponse)
        .then(token => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', token);
            return token;
        }).catch((error) => {
            alertService.error(error.data);
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    window.location.href = '/';
}

function generateHmac(userEmail) {
    return Base64.stringify(hmacSHA256(userEmail, "95e91a9f1f6dbb8b8a57fd19e95e7f7848b0357b"))
}
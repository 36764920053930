import React from 'react'

export const numberHelper = {
    generate,
    generateForOptions
};

function generate() {
    const min = 0;
    const max = 99;
    const numbers = [];
    for(let i = min; i <= max; i++) {
        numbers.push(i);
    }
    
    return numbers;
}

function generateForOptions() {
    const numbers = generate();
    const options = [];
    for(let i = 0; i < numbers.length; i++) {
        options.push({
            label: i,
            value: i
        });
    }

    return options;
    
}
import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {Link, useHistory} from "react-router-dom";
import {authenticationService} from "../_services/authentication.service";
import {Role} from "../_helpers/role";
import AuthProvider from "../AuthProvider";

const TheConfigMenu = ({...props}) => {
  const history = useHistory();
  if (!props.config) {
    props.config = "14.4px";
  }
  return (
      <CDropdown
          inNav
          className="c-header-nav-items mx-2"
          direction="down"
      >
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="c-avatar" style={{"fontSize": props.config.size}}>
            Configuración
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor]}>
            <CDropdownItem onClick={() => {
              history.push("/users");
            }}>
              <CIcon name="cil-user" className="mfe-2" />Usuarios
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor, Role.Seller, Role.Donor]}>
            <CDropdownItem onClick={() => {
              history.push(`/users/editProfile/${authenticationService.getUserId}`);
            }}>
              <CIcon name="cil-user" className="mfe-2" />Editar perfil
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/manageUserBalance");
            }}>
              <CIcon name="cil-user" className="mfe-2" />Saldos de mi equipo
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/lotteries");
            }}>
              <CIcon name="cil-file" className="mfe-2" />Sorteos
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/restrictions");
            }}>
              <CIcon name="cil-applications-settings" className="mfe-2" />Restricciones
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/sale/restrictions");
            }}>
              <CIcon name="cil-applications-settings" className="mfe-2" />Restricciones de ventas
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/automaticRestrictions");
            }}>
              <CIcon name="cil-applications-settings" className="mfe-2" />Restricciones automáticas
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/projects");
            }}>
              <CIcon name="cil-paperclip" className="mfe-2" />Proyectos
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/requests");
            }}>
              <CIcon name="cil-file" className="mfe-2" />Solicitudes de acreditación de saldos
            </CDropdownItem>
          </AuthProvider>
          <AuthProvider roles={[Role.Manager]}>
            <CDropdownItem onClick={() => {
              history.push("/chat");
            }}>
              <CIcon name="cil-paperclip" className="mfe-2" />Enviar notificaciones
            </CDropdownItem>
          </AuthProvider>
          <CDropdownItem onClick={() => {
            authenticationService.logout()
          }}>
            Cerrar sesión
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
  )
}

export default TheConfigMenu
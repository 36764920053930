import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {crudService} from "../../_services/crud.service";
import {
    CContainer,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CDataTable,
    CCard,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle, CFormGroup, CLabel, CInput, CCardFooter, CForm
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import {alertService} from "../../_services/alert.service";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const fields = [
    {key: 'userName', label: 'Nombre', _style: {width: '20%'}},
    {key: 'amount', label: 'Monto', _style: {width: '10%'}},
    {key: 'paymentMethod', label: 'Método de pago', _style: {width: '20%'}},
    {key: 'phoneNumber', label: 'Teléfono', _style: {width: '10%'}},
    {key: 'transactionNumber', label: '# transacción', _style: {width: '20%'}},
    {key: 'date', label: 'Fecha de recarga', _style: {width: '20%'}},
    {
        key: 'show_details',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    },
    {
        key: 'delete',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const RechargeApproval = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [recharges, setRecharges] = useState([])
    const [danger, setDanger] = useState(false)
    const [approve, setApprove] = useState(false)
    const [reasonForDenied, setReasonForDenied] = useState(null)
    const [selectedRecharge, setSelectedRecharge] = useState({})

    function approveRecharge() {
        crudService.post('/recharge/approveRecharge',
            selectedRecharge,
            fetchRecharges,
            'Ocurrió un error aprobando la recarga.')
    }

    function denyRecharge() {
        crudService.post('/recharge/denyRecharge',
            {
                ...selectedRecharge,
                reasonForDenied: reasonForDenied
            },
            fetchRecharges,
            'Ocurrió un error denegando la recarga.')
    }

    async function fetchRecharges() {
        await crudService.list('recharge/getMyTeamRecharges', setRecharges, setLoading);
    }

    const renderRechargesTable = () => {
        const handleDeniedRecharge = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (!reasonForDenied) {
                    alertService.error("La razón de rechazo es requerida.");
                    return;
                }

                denyRecharge();
                setDanger(!danger);
            }
        }

        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={danger}
                                onClose={() => setDanger(!danger)}
                                color="danger"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleDeniedRecharge}>
                                        <CFormGroup>
                                            <CLabel>¿Está seguro que desea rechazar la recarga?</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="reasonForDenied">Razón de rechazo</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="reasonForDenied"
                                                    onChange={(e) => {
                                                        setReasonForDenied(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="danger" className="mr-2"><CIcon
                                                name="cil-save"/> Rechazar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setDanger(!danger)
                                                setSelectedRecharge({})
                                            }}>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={approve}
                                onClose={() => setApprove(!approve)}
                                color="primary"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    ¿Está seguro que desea aprobar la recarga?
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="primary" onClick={() => {
                                        setApprove(!approve);
                                        approveRecharge();
                                    }}>Sí</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setApprove(!approve)
                                    }}>No</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={12}>
                                            <strong>Lista de recargas</strong><br/>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={recharges}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay recargas', noItems: 'No hay recargas'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'show_details':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setApprove(!approve)
                                                                    setSelectedRecharge(item)
                                                                }}
                                                            >
                                                                Aprobar
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'delete': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDanger(!danger);
                                                                setSelectedRecharge(item);
                                                            }}
                                                        >
                                                            Rechazar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        )
    }

    useEffect(() => {
        fetchRecharges()
    }, []);

    let contents = loading
        ? <p><em>Cargando...</em></p>
        : renderRechargesTable();

    return (contents)
}

export default RechargeApproval
import React from 'react'
import {
    CBadge,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {useHistory} from "react-router-dom";

const TheHeaderDropdownNotif = (props) => {
    const history = useHistory()
    if (!props.notifications) {
        props.notifications = []
    }
    
    if (!props.customClassName) {
        props.customClassName = "nothing"
    }
    
    const itemsCount = props.notifications.length

    const notifList = props.notifications.map(m =>
        <CDropdownItem onClick={e => {
            let currentPath = window.location.hash;
            if (currentPath === m.url) {
                window.location.reload(false);
            }
            history.push(`${currentPath}/replace`);
            setTimeout(() => {
                history.push(m.url)
            }, 0)
        }}>
            <CIcon name="cil-bell" className="mr-2 text-success"/><div className="text-truncate">{m.message}</div>
        </CDropdownItem>)

    return (
        <CDropdown
            inNav
            className="c-header-nav-item mx-2"
        >
            <CDropdownToggle className="c-header-nav-link" caret={false}>
                <CIcon name="cil-bell"/>
                <CBadge shape="pill" color="danger" className={props.customClassName}>{itemsCount}</CBadge>
            </CDropdownToggle>
            <CDropdownMenu placement="bottom-end" className="pt-0">
                <CDropdownItem
                    header
                    tag="div"
                    className="text-center"
                    color="light"
                >
                    <strong>Tiene {itemsCount} notificaciones</strong>
                </CDropdownItem>
                {notifList}
            </CDropdownMenu>
        </CDropdown>
    )
}

export default TheHeaderDropdownNotif
export const hoursHelper = {
    generateForOptions,
    toAMPM
};

function toAMPM(value) {
    if (value === undefined) {
        return "-";
    }

    const totalMinutes = value * 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60); // This will give you exact 5-minute increments

    const isPM = hours >= 12;
    const adjustedHour = (hours % 12) || 12;

    return `${adjustedHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${isPM ? 'PM' : 'AM'}`;
}

function generateForOptions() {
    const min = 0;  // Starting at 12:00 AM
    const max = 24; // Up to 12:00 AM of the next day
    const interval = 5 / 60;  // 5 minutes as a fraction of an hour
    const options = [];

    for (let value = min; value < max; value += interval) {
        const valueRounded = Math.round(value * 100) / 100;  // Rounding to two decimal places
        if (valueRounded === 24) {
            continue;
        }
        const label = toAMPM(valueRounded);
        options.push({
            label: label,
            value: valueRounded
        });
    }

    return options;
}
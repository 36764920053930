import React, {useEffect, useState} from 'react'
import es from 'date-fns/locale/es';
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import Select from "react-select";
import {authenticationService} from "../../_services/authentication.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {selectHelper} from "../../_helpers/select.helper";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const User = ({match}) => {
    const history = useHistory();
    const [existingUser, setUser] = useState({
        projectIds: []
    });
    const [roles, setRoles] = useState([]);
    const [bosses, setBosses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [projects, setProjects] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const { id } = match.params;
    const isAddMode = !id;
    
    useEffect(() => {
        if (!isAddMode) {
            async function fetchUser() {
                crudService.get(`user/${id}`, (data) => {
                    setUser(data);
                }, 'Ocurrió un error obteniendo el usuario')
            }

            fetchUser()
        }
    }, []);
    
    useEffect(() => {
       async function fetchCountries() {
           crudService.get('/country/getCurrentUserCountry', (data) => {
               selectHelper.populateSelectControl([data], "id", "name", setCountries);
           }, 'Ocurrió un error listando los paises.');
       }
       
       fetchCountries();
    }, [existingUser.countryId]);
    
    useEffect(() => {
        async function fetchRoles() {
            crudService.get('role', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setRoles);
            }, 'Ocurrió un error listando los roles.');
        }

        fetchRoles();
    }, []);

    useEffect(() => {
        async function fetchProjects() {
            crudService.get('project/getAllProjects/false', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setProjects);
            }, 'Ocurrió un error listando las zonas.');
        }

        fetchProjects();
    }, []);
    
    useEffect(() => {
        async function fetchPaymentMethods() {
            if (!isAddMode && existingUser.paymentMethods && existingUser.paymentMethods.length > 0) {
                setPaymentMethods(existingUser.paymentMethods);
                return;
            }
            
            if (!existingUser.countryId) {
                return;
            }
            crudService.get(`/country/getCountryPaymentMethods/${existingUser.countryId}`, (data) => {
                setPaymentMethods(data);
            }, 'Ocurrió un error listando los métodos de pago.');
        }
        
        fetchPaymentMethods();
    }, [existingUser.countryId]);

    useEffect(() => {
        async function fetchBosses() {
            if (!existingUser.roleId) {
                return;
            }
            crudService.get(`user/getPossibleBosses/${existingUser.roleId}`, (data) => {
                selectHelper.populateSelectControl(data, "id", "fullName", setBosses);
            }, 'Ocurrió un error listando los usuarios.');
        }

        fetchBosses();
    }, [existingUser.roleId]);
    
    function updateUser() {
        if (typeof existingUser.birth === 'string') {
            existingUser.birth = new Date(existingUser.birth);
        }
        existingUser.yearBirth = existingUser.birth.getFullYear();
        existingUser.monthBirth = existingUser.birth.getMonth() + 1;
        existingUser.dayBirth = existingUser.birth.getDate();
        crudService.update('/user', existingUser,
            (data) => {},
            'El usuario se modificó correctamente.',
            'Ocurrió un error modificando el usuario.');
    }
    
    function createUser() {
        crudService.create('/user',
            {
                yearBirth: existingUser.birth.getFullYear(),
                monthBirth: existingUser.birth.getMonth() + 1,
                dayBirth: existingUser.birth.getDate(),
                ...existingUser
            },
            (response) => {
                alertService.success('El usuario se creó correctamente.');
                setUser(response.data);
                history.push('/users')
            },
            'Ocurrió un error creando el usuario.')
    }
    
    const renderUserForm = () => {
        const handleSubmit = event => {
            event.preventDefault();
            
            const form = event.currentTarget;
            
            if (form.checkValidity()) {
                if (authenticationService.isManager && existingUser.projectIds.length === 0) {
                    alertService.error("El proyecto es requerido.");
                    return;
                }
                if (!existingUser.countryId) {
                    alertService.error("El país es requerido.");
                    return;
                }
                if (!existingUser.roleId) {
                    alertService.error("El rol es requerido.");
                    return;
                }
                if (!existingUser.bossId) {
                    alertService.error("El encargado es requerido.");
                    return;
                }
                if (!existingUser.birth) {
                    alertService.error("La fecha de nacimiento es requerida.");
                    return;
                }
                if (!existingUser.paymentMethods || existingUser.paymentMethods.length === 0) {
                    alertService.error("La información bancaria es requerida.");
                    return;
                }
                isAddMode ? createUser() : updateUser();
            }
        }
        return (
            <AuthProvider roles={[Role.Manager, Role.Admin, Role.Supervisor]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={6}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{isAddMode ? 'Crear usuario' : 'Editar usuario'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup className={authenticationService.sameAsCurrentUser(id) || !isAddMode ? 'd-none' : ''}>
                                            <CLabel htmlFor="role">Rol</CLabel>
                                            <Select
                                                required
                                                value={roles.filter(option => option.value === existingUser.roleId)}
                                                onChange={(e) => {
                                                    setUser({...existingUser, roleId: e.value});
                                                }}
                                                placeholder={"Seleccione un rol"}
                                                noOptionsMessage={() => "No hay roles"}
                                                options={roles}/>
                                            <CInvalidFeedback invalid="false">El rol es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup className={authenticationService.sameAsCurrentUser(id) ? 'd-none' : ''}>
                                            <CLabel htmlFor="bossId">Encargado</CLabel>
                                            <Select
                                                required
                                                isDisabled={!isAddMode}
                                                value={bosses.filter(option => option.value === existingUser.bossId)}
                                                onChange={(e) => {
                                                    setUser({...existingUser, bossId: e.value});
                                                }}
                                                placeholder={"Seleccione un encargado"}
                                                noOptionsMessage={() => "No hay encargados"}
                                                options={bosses}/>
                                            <CInvalidFeedback invalid="false">El encargado es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="name">Número documento identidad</CLabel>
                                            <CInput type="text"
                                                    autoComplete="false"
                                                    required
                                                    id="dni"
                                                    defaultValue={existingUser.dni}
                                                    onChange={(e) => {
                                                        setUser({...existingUser, dni: e.target.value});
                                                    }}
                                            />
                                            <CInvalidFeedback>El número documento identidad es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="name">Nombre Punto de Venta</CLabel>
                                            <CInput type="text"
                                                    autoComplete="false"
                                                    required
                                                    id="name"
                                                    defaultValue={existingUser.name}
                                                    onChange={(e) => {
                                                        setUser({...existingUser, name: e.target.value});
                                                    }}
                                            />
                                            <CInvalidFeedback>El nombre del punto de venta es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="lastname">Apellidos Usuario</CLabel>
                                            <CInput
                                                type="text"
                                                required
                                                autoComplete="false"
                                                id="lastname"
                                                defaultValue={existingUser.lastname}
                                                onChange={(e) => {
                                                    setUser({...existingUser, lastname: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">Los apellidos del usuario son requeridos</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup className={authenticationService.isManager ? '' : 'd-none'}>
                                            <CLabel htmlFor="projectIds">Zonas</CLabel>
                                            <Select
                                                required={true}
                                                isMulti
                                                value={projects.filter(option => existingUser.projectIds.includes(option.value))}
                                                onChange={(e) => {
                                                    existingUser.projectIds = [];
                                                    const projectIds = [];
                                                    for (let i = 0; i < e.length; i++) {
                                                        projectIds.push(e[i].value);
                                                    }
                                                    setUser({...existingUser, projectIds: projectIds});
                                                }}
                                                placeholder={"Seleccione las zonas"}
                                                noOptionsMessage={() => "No hay zonas"}
                                                options={projects}/>
                                            <CInvalidFeedback invalid="false">El proyecto es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="birth">Fecha de nacimiento</CLabel>
                                            <DatePicker
                                                id="birth"
                                                dateFormat="dd/MM/yyyy"
                                                selected={existingUser.birth ? new Date(existingUser.birth) : null}
                                                autoComplete="false"
                                                required
                                                locale={es}
                                                onChange={(date) => setUser({...existingUser, birth: date})}
                                                className="form-control"
                                            />
                                            <CInvalidFeedback invalid="false">La fecha de nacimiento es requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="address">Dirección</CLabel>
                                            <CInput
                                                required
                                                autoComplete="false"
                                                id="address"
                                                defaultValue={existingUser.address}
                                                onChange={(e) => {
                                                    setUser({...existingUser, address: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">La dirección es requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="email">Email</CLabel>
                                            <CInput
                                                required
                                                autoComplete="false"
                                                id="email"
                                                defaultValue={existingUser.email}
                                                onChange={(e) => {
                                                    setUser({...existingUser, email: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El correo electrónico es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phone">Teléfono</CLabel>
                                            <CInput
                                                required
                                                autoComplete="false"
                                                id="phone"
                                                defaultValue={existingUser.phone}
                                                onChange={(e) => {
                                                    setUser({...existingUser, phone: e.target.value});
                                                }}/>
                                            <CInvalidFeedback invalid="false">El teléfono es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="phone2">Nombre propietario Punto venta</CLabel>
                                            <CInput
                                                autoComplete="false"
                                                id="phone2"
                                                defaultValue={existingUser.phone2}
                                                onChange={(e) => {
                                                    setUser({...existingUser, phone2: e.target.value});
                                                }}/>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="username">Nombre de usuario (iniciar sesión)</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="username"
                                                    defaultValue={existingUser.username}
                                                    onChange={(e) => {
                                                        setUser({...existingUser, username: e.target.value});
                                                    }}/>
                                            <CInvalidFeedback invalid="false">El nombre de usuario es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="password">Contraseña</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="password"
                                                    type="password"
                                                    defaultValue={existingUser.password}
                                                    onChange={(e) => {
                                                        setUser({...existingUser, password: e.target.value});
                                                    }}/>
                                            <CInvalidFeedback invalid="false">La contraseña es requerida.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Guardar</CButton>
                                            <Link to='/users' className="btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg={6}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Información bancaria'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm onSubmit={(e) => {e.preventDefault(); return false;}}>
                                        <CFormGroup>
                                            <CLabel htmlFor="countryCheck" style={{marginRight: "1.5%"}}>País:</CLabel>
                                            <Select
                                                isDisabled={!isAddMode}
                                                required
                                                value={countries.filter(option => option.value === existingUser.countryId)}
                                                onChange={(e) => {
                                                    setUser({...existingUser, countryId: e.value});
                                                }}
                                                placeholder={"Seleccione un país"}
                                                noOptionsMessage={() => "No hay paises"}
                                                options={countries}/>
                                        </CFormGroup>
                                        {
                                            paymentMethods.map((paymentMethod, i) => {
                                                return <CFormGroup key={paymentMethod.id}>
                                                    <CLabel>{paymentMethod.paymentMethodName}</CLabel>
                                                    <CInput
                                                        autoComplete="false"
                                                        value={paymentMethod.value}
                                                        onChange={(e) => {
                                                            const paymentMethods = existingUser.paymentMethods || [];
                                                            let payM = paymentMethods.find(value => value.paymentMethodId === e.target.id);
                                                            if (!payM) {
                                                                payM = {
                                                                    paymentMethodId: e.target.id,
                                                                    value: e.target.value,
                                                                    userId: existingUser.id
                                                                };
                                                                paymentMethods.push(payM);
                                                            } else {
                                                                payM.paymentMethodId = e.target.id;
                                                                payM.value = e.target.value;
                                                                payM.userId = existingUser.id;
                                                            }
                                                            setUser({...existingUser, paymentMethods: paymentMethods});
                                                        }}
                                                        id={paymentMethod.paymentMethodId || paymentMethod.id}/>
                                                </CFormGroup>
                                            })
                                        }
                                        <CCardFooter>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderUserForm(existingUser))
}

export default User

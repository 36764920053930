import React from 'react'

export const selectHelper = {
    populateSelectControl
};

function populateSelectControl(data, idProperty, valueProperty, setFunction) {
    const options = [];
    for (let i = 0; i < data.length; i++) {
        options.push({
            value: data[i][idProperty],
            label: data[i][valueProperty]
        })
    }
    setFunction(options);
};
import React from 'react';
import { authenticationService } from '../_services/authentication.service';

export const SecureHelper = {
    canAccess
};

function canAccess(roles) {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {
        return false;
    }
    
    return !(roles && roles.indexOf(currentUser.role) === -1);
    
    
}
import React from 'react'
import axios from "axios";
import {alertService} from "./alert.service";
import { authHeader } from "../_helpers/auth-header";

export const crudService = {
    create,
    update,
    remove,
    list,
    get,
    getWithParams,
    post
};

function get(url, setFunction, errorMessage) {
    axios.get(url, {
        headers: authHeader()
    })
        .then(function (response) {
            setFunction(response.data)
        })
        .catch(function (error) {
            showError(error, errorMessage);
        });
}

function getWithParams(url, params, setFunction, errorMessage) {
    axios.get(url, {
        headers: authHeader(),
        params: params
    })
        .then(function (response) {
            setFunction(response.data)
        })
        .catch(function (error) {
            showError(error, errorMessage);
        });
}

function create(url, data, then, errorMessage) {
    post(url, data, then, errorMessage);
}

function post(url, data, then, errorMessage) {
    axios.post(url, data, {headers: authHeader()})
        .then(function (response) {
            then(response);
        })
        .catch(function (error) {
            showError(error, errorMessage);
        });
}

function update(url, data, setFunction, successMessage, errorMessage) {
    axios.put(url, data, {
        headers: authHeader()
    })
        .then(function (response) {
            alertService.success(successMessage);
            setFunction(response.data);
        })
        .catch(function (error) {
            showError(error, errorMessage);
        });
}

function remove(url, data, setFunction, fetchFunction, successMessage, errorMessage) {
    axios.delete(url, {
        headers: authHeader(true),
        data: data
    }).then(function (response) {
        alertService.success(successMessage);
        setFunction({});
        fetchFunction();
    })
        .catch(function (error) {
            showError(error, errorMessage);
            setFunction({})
        });
}

async function list(url, setFunction, setLoading) {
    axios.get(url, {
        headers: authHeader()
    })
        .then(function (response) {
            setFunction(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            showError(error, "Ocurrió un error. Por favor intente de nuevo.");
            setLoading(false);
        });
}

function showError(error, errorMessage) {
    if (!error) {
        error = {};
    }
    if (error.status === 500) {
        alertService.error(errorMessage ? errorMessage : "Ocurrió un error. Por favor intente de nuevo.");
    } else {
        alertService.error(error.data ? error.data : "Ocurrió un error. Por favor intente de nuevo.");
    }
}
import React, {useState, useEffect} from 'react';
import {crudService} from "../../_services/crud.service";
import {
    CContainer,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CDataTable,
    CCard,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle, CFormGroup, CLabel, CInput, CCardFooter, CForm
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import {alertService} from "../../_services/alert.service";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const fields = [
    {key: 'userName', label: 'Nombre', _style: {width: '20%'}},
    {key: 'userDni', label: 'Identificación', _style: {width: '20%'}},
    {key: 'formatCurrentBalance', label: 'Monto actual', _style: {width: '20%'}},
    {
        key: 'increase_balance',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    },
    {
        key: 'decrease_balance',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const ManageUserBalance = () => {
    const [loading, setLoading] = useState(true)
    const [usersBalance, setUsersBalance] = useState([])
    const [decreaseUserBalanceModal, setDecreaseUserBalanceModal] = useState(false)
    const [increaseUserBalanceModal, setIncreaseUserBalanceModal] = useState(false)
    const [reasonForDecrease, setReasonForDecrease] = useState(null)
    const [reasonForIncrease, setReasonForIncrease] = useState(null)
    const [amountToIncrease, setAmountToIncrease] = useState(0)
    const [amountToDecrease, setAmountToDecrease] = useState(0)
    const [selectedUserBalance, setSelectedUserBalance] = useState({})
    const [currentPassword, setCurrentPassword] = useState("");
    const [modify, setModify] = useState(false);
    const [action, setAction] = useState("");
    
    function doDecreaseUserBalance() {
        crudService.post('/recharge/decreaseUserBalance',
            {
                ...selectedUserBalance,
                reason: reasonForDecrease,
                amount: amountToDecrease
            }, 
            () => {
                fetchMyTeamBalances();
                setDecreaseUserBalanceModal(false);
                setIncreaseUserBalanceModal(false);
            },
            'Ocurrió un error descontando.')
    }

    function doIncreaseUserBalance() {
        crudService.post('/recharge/increaseUserBalance',
            {
                ...selectedUserBalance,
                reason: reasonForIncrease,
                amount: amountToIncrease
            },
            () => {
                fetchMyTeamBalances();
                setDecreaseUserBalanceModal(false);
                setIncreaseUserBalanceModal(false);
            },
            'Ocurrió un error recargando.')
    }

    async function fetchMyTeamBalances() {
        await crudService.list('recharge/getMyTeamBalances', setUsersBalance, setLoading);
    }

    const renderRechargesTable = () => {
        const handleDecreaseUserBalance = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (!reasonForDecrease) {
                    alertService.error("La razón de descontar es requerida.");
                    return;
                }
                
                if (amountToDecrease <= 0) {
                    alertService.error("El monto a descontar debe ser mayor a 0.");
                    return;
                }

                setAction("decrease");
                setDecreaseUserBalanceModal(!decreaseUserBalanceModal);
                setModify(true);
            }
        }
        
        const handleIncreaseUserBalance = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                if (!reasonForIncrease) {
                    alertService.error("La razón de recarga es requerida.");
                    return;
                }
                
                if (amountToIncrease <= 0) {
                    alertService.error("El monto a recargar debe ser mayor a 0.");
                    return;
                }

                setAction("increase");
                setIncreaseUserBalanceModal(!increaseUserBalanceModal);
                setModify(true);
            }
        }

        const handlePasswordValidation = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                crudService.post("user/validatePassword", {
                    password: currentPassword
                }, () => {
                    setModify(!modify);
                    setCurrentPassword("");
                    alertService.success("Contraseña correcta.");
                    if (action === "increase") {
                        doIncreaseUserBalance();
                    } else {
                        doDecreaseUserBalance();
                    }
                }, "Ocurrió un error validando la contraseña.");
            }
        }

        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={decreaseUserBalanceModal}
                                onClose={() => setDecreaseUserBalanceModal(!decreaseUserBalanceModal)}
                                color="danger"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleDecreaseUserBalance}>
                                        <CFormGroup>
                                            <CLabel>¿Está seguro que desea descontar un monto de recarga?</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="amountToDecrease">Monto a descontar</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="amountToDecrease"
                                                    type="number"
                                                    min="1"
                                                    onChange={(e) => {
                                                        setAmountToDecrease(parseFloat(e.target.value));
                                                    }}/>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="reasonForDenied">Razón de desconte</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="reasonForDenied"
                                                    onChange={(e) => {
                                                        setReasonForDecrease(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="danger" className="mr-2"><CIcon
                                                name="cil-save"/> Descontar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setDecreaseUserBalanceModal(!decreaseUserBalanceModal)
                                                setSelectedUserBalance({})
                                            }}>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={increaseUserBalanceModal}
                                onClose={() => setIncreaseUserBalanceModal(!increaseUserBalanceModal)}
                                color="primary"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleIncreaseUserBalance}>
                                        <CFormGroup>
                                            <CLabel>¿Está seguro que desea recargar un monto al usuario?</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="amountToIncrease">Monto a recargar</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="amountToIncrease"
                                                    type="number"
                                                    min="1"
                                                    onChange={(e) => {
                                                        setAmountToIncrease(parseFloat(e.target.value));
                                                    }}/>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="reasonForIncrease">Razón de recarga</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="reasonForIncrease"
                                                    onChange={(e) => {
                                                        setReasonForIncrease(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Recargar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setIncreaseUserBalanceModal(!increaseUserBalanceModal)
                                                setSelectedUserBalance({})
                                            }}>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={modify}
                                onClose={() => setModify(!modify)}
                                color="warning">
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handlePasswordValidation}>
                                        <CFormGroup>
                                            <CLabel>Para modificar el balance del usuario debe ingresar su contraseña</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="password">Contraseña</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="password"
                                                    type="password"
                                                    value={currentPassword}
                                                    onChange={(e) => {
                                                        setCurrentPassword(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-scrubber"/> Validar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setModify(!modify)
                                            } }>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={12}>
                                            <strong>Lista de recargas</strong><br/>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={usersBalance}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay usuarios', noItems: 'No hay usuarios'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'increase_balance':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setIncreaseUserBalanceModal(!increaseUserBalanceModal)
                                                                    setSelectedUserBalance(item)
                                                                }}
                                                            >
                                                                Recargar
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'decrease_balance': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDecreaseUserBalanceModal(!decreaseUserBalanceModal);
                                                                setSelectedUserBalance(item);
                                                            }}
                                                        >
                                                            Descontar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        )
    }

    useEffect(() => {
        fetchMyTeamBalances()
    }, []);

    let contents = loading
        ? <p><em>Cargando...</em></p>
        : renderRechargesTable();

    return (contents)
}

export default ManageUserBalance
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {crudService} from "../../_services/crud.service";
import {
    CContainer,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CDataTable,
    CCard,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle
} from '@coreui/react'
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const fields = [
    {key: 'description', label: 'Descripción', _style: {width: '20%'}},
    {key: 'prize', label: 'Premio', _style: {width: '20%'}},
    {key: 'prizePoolFormatted', label: 'Acumulado %', _style: {width: '20%'}},
    {key: 'bucketFormatted', label: 'Fondos proyecto %', _style: {width: '20%'}},
    {key: 'closeAmPmFormat', label: 'Hora de Cierre', _style: {width: '20%'}},
    {
        key: 'show_details',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    },
    {
        key: 'delete',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const Lotteries = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [lotteries, setLotteries] = useState([])
    const [danger, setDanger] = useState(false)
    const [selectedLottery, setSelectedLottery] = useState({})
    
    function deleteLottery() {
        crudService.remove('/lottery',
            selectedLottery,
            setSelectedLottery,
            fetchLotteries,
            'El sorteo se eliminó correctamente.',
            'Ocurrió un error eliminando el sorteo.')
    }

    async function fetchLotteries() {
        await crudService.list('lottery', setLotteries, setLoading);
    }

    const renderLotteriesTable = () => {
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={danger}
                                onClose={() => setDanger(!danger)}
                                color="danger"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    ¿Está seguro que desea eliminar el sorteo?
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="danger" onClick={() => {
                                        setDanger(!danger);
                                        deleteLottery();
                                    }}>Sí</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setDanger(!danger)
                                        setSelectedLottery({})
                                    } }>No</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={6}>
                                            <strong>Lista de sorteos</strong><br/>
                                        </CCol>
                                        <CCol lg={6}>
                                            <CButton onClick={() => {
                                                history.push(`/lotteries/create`)
                                            }} type="button" size="md" color="primary" className="mr-2 float-right">+ Crear nuevo sorteo</CButton>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={lotteries}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay sorteos', noItems: 'No hay sorteos'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'show_details':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    history.push(`/lotteries/edit/${item.id}`)
                                                                }}
                                                            >
                                                                Modificar
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'delete': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDanger(!danger);
                                                                setSelectedLottery(item);
                                                            }}
                                                        >
                                                            Eliminar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        )
    }

    useEffect(() => {
        fetchLotteries()
    }, []);

    let contents = loading
        ? <p><em>Cargando...</em></p>
        : renderLotteriesTable();

    return (contents)
}

export default Lotteries
import React from 'react'
import { CFooter, CImg } from '@coreui/react'

const TheFooter = () => {
    return (
        <CFooter fixed={false}>
            <div className="d-sm-down-none">
                <CImg style={{height:40}} src={require("../assets/images/fundeporte.jpg").default}></CImg>
            </div>
            <div>
                <a href="https://puntogana.com/" target="_blank" rel="noopener noreferrer">Punto Gana</a>
                <span className="ml-1">&copy; 2023 Punto Gana | www.puntogana.com.</span>
            </div>
        </CFooter>
    )
}

export default React.memo(TheFooter)

import React, {useEffect, useState} from 'react';
import es from 'date-fns/locale/es';
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CContainer,
    CLabel,
    CInput,
    CDataTable,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormGroup,
    CSwitch,
    CInvalidFeedback, CCardFooter, CForm, CImg
} from '@coreui/react'
import Select from "react-select";
import {crudService} from "../../_services/crud.service";
import CIcon from "@coreui/icons-react";
import {alertService} from "../../_services/alert.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {numberHelper} from "../../_helpers/number.helper";
import {selectHelper} from "../../_helpers/select.helper";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const movementsFields = [
    {key: 'lottery', label: 'Sorteo', _style: {width: '20%'}},
    {key: 'bill', label: '# Tiquete', _style: {width: '20%'}},
    {key: 'number', label: 'Número', _style: {width: '20%'}},
    {key: 'amount', label: 'Monto', _style: {width: '20%'}},
    {key: 'prizeApplied', label: 'Aplicado', _style: {width: '20%'}},
    {key: 'prize', label: 'Premio', _style: {width: '20%'}},
    {key: 'prizePaid', label: 'Pagado', _style: {width: '20%'}},
    {key: 'client', label: 'Cliente', _style: {width: '20%'}},
    {key: 'isCancelled', label: 'Anulado', _style: {width: '20%'}},
    {key: 'show_details', label: '', _style: { width: '1%' }, sorter: false, filter: false}
]

const Movements = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedLottery, setSelectedLottery] = useState(null);
    const [lotteriesOptions, setLotteriesOptions] = useState([]);
    const [lotteries, setLotteries] = useState([]);
    const [users, setUsers] = useState([]);
    const [winnerNumber, setWinnerNumber] = useState(null);
    const [movements, setMovements] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [info, setInfo] = useState(false);
    const [modify, setModify] = useState(false);
    const [prize, setPrize] = useState(0);
    const [balance, setBalance] = useState(0);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isPrizeDisabled, setIsPrizeDisabled] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRoleId, setSelectedRoleId] = useState("00000000-0000-0000-0000-000000000000");
    const [selectedUserId, setSelectedUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [numbers, setNumbers] = useState([]);
    const [currentPassword, setCurrentPassword] = useState("");
    const [showReceipt, setShowReceipt] = useState(false);
    const [newBillNumber, setNewBillNumber] = useState(null);
    const [sourceImg, setSourceImg] = useState("");
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState("00000000-0000-0000-0000-000000000000");
    const [onlyWinnerNumbers, setOnlyWinnerNumbers] = useState(false);

    useEffect(() => {
        async function fetchLotteries() {
            crudService.get(`lottery/getByCountryId`, (data) => {
                setLotteries(data);
                selectHelper.populateSelectControl(data, "id",
                    "description",
                    setLotteriesOptions);
            }, 'Ocurrió un error listando los sorteos.')
        }

        fetchLotteries();
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            crudService.get('user', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setUsers);
            }, 'Ocurrió un error listando los sorteos.');
        }

        fetchUsers();
    }, []);

    useEffect(() => {
        async function fetchRoles() {
            crudService.get('role/getAllRoles/true', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setRoles);
            }, 'Ocurrió un error listando los roles.');
        }

        fetchRoles();
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            const roleId = selectedRoleId ? selectedRoleId : "00000000-0000-0000-0000-000000000000";
            crudService.get(`user/getAllUsers/true/${roleId}`, (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setUsers);
            }, 'Ocurrió un error listando los usuarios.');
        }

        fetchUsers();
    }, [selectedRoleId]);
    
    useEffect(() => {
        getPrize(true);
        fetchMovements();
    }, [selectedLottery, selectedDate, onlyWinnerNumbers, selectedProjectId, selectedUserId]);
    
    useEffect(() => {
        if (!movements.length) {
            return;
        }
        
        let amount = 0;
        for(let i = 0; i < movements.length; i++) {
            amount += movements[i].amount
        }
        
        setTotalAmount(amount);
    }, [movements]);

    useEffect(() => {
        setNumbers(numberHelper.generateForOptions());
    }, []);

    useEffect(() => {
        async function fetchProjects() {
            crudService.get(`project/getAllProjects/false`, (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setProjects);
            }, 'Ocurrió un error listando las zonas.');
        }

        fetchProjects();
    }, []);
    
    function applyPrize() {
        const lottery = lotteries.find(l => l.id === selectedLottery);
        if (!lottery) {
            return;
        }

        crudService.post('prize/applyPrizes', {
            lotteryId: selectedLottery,
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1,
            day: selectedDate.getDate(),
            winner: winnerNumber
        }, (response) => {
            alertService.success('El premio se aplicó correctamente.');
            setIsDisabled(true);
            const timer = setTimeout(() => {
                getPrize(false);
                fetchMovements();
            }, 1000);
        }, 'Ocurrió un error aplicando el premio.')
    }
    
    function getPrize(showAlert = true) {
        crudService.getWithParams('prize/getprize', {
            "lotteryId": selectedLottery === null ? null : selectedLottery,
            "year": selectedDate.getFullYear(),
            "month": selectedDate.getMonth() + 1,
            "day": selectedDate.getDate(),
        }, (data) => {
            if (!data) {
                setBalance(0);
                setTotalAmount(0);
                setPrize(0);
                setWinnerNumber(null);
                setIsDisabled(false);
                setIsPrizeDisabled(false);
                return;
            }
            setBalance(data.balance);
            setTotalAmount(data.totalAmount);
            setPrize(data.totalPrize);
            setWinnerNumber(data.winner);
            setIsDisabled(true);
            setIsPrizeDisabled(true);
            if (showAlert) {
                alertService.info("Ya se registró el premio para este sorteo y esta fecha.");
            }
        }, 'Ocurrió un error obteniendo el premio.');
    }

    function fetchMovements() {
        if (!selectedDate) {
            setMovements([]);
            setBalance(0);
            setPrize(0);
            return;
        }

        crudService.getWithParams('sale/getmovements', {
            "lotteryId": selectedLottery === null ? null : selectedLottery,
            "year": selectedDate.getFullYear(),
            "month": selectedDate.getMonth() + 1,
            "day": selectedDate.getDate(),
            "projectId": selectedProjectId === null ? null : selectedProjectId,
            "userId": selectedUserId === null ? null : selectedUserId,
            "showWinningNumber": onlyWinnerNumbers
        }, (data) => {
            setMovements(data);
        }, 'Ocurrió un error obteniendo los movimientos.');
    }
    
    function saveWinner() {
        const lottery = lotteries.find(l => l.id === selectedLottery);
        if (!lottery) {
            return;
        }

        crudService.create('/prize', {
            lotteryId: selectedLottery,
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1,
            day: selectedDate.getDate(),
            winner: winnerNumber
        }, (response) => {
            alertService.success('El premio se creó correctamente.');
            setIsDisabled(true);
            const timer = setTimeout(() => {
                getPrize(false);
                fetchMovements();
            }, 1000);
        }, 'Ocurrió un error guardando el premio.')
    }

    const renderMovementsForm = () => {
        const handleSubmit = event => {
            event.preventDefault();

            const form = event.currentTarget;

            if (form.checkValidity()) {
                crudService.post("user/validatePassword", {
                    password: currentPassword
                }, () => {
                    setModify(!modify);
                    setCurrentPassword("");
                    alertService.success("Contraseña correcta.");
                    setIsDisabled(false);
                    setIsPrizeDisabled(false);
                }, "Ocurrió un error validando la contraseña.");
            }
        }
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={info}
                                onClose={() => setInfo(!info)}
                                color="info"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    ¿Está seguro que desea guardar el número?
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="primary" onClick={() => {
                                        setInfo(!info);
                                        saveWinner();
                                    }}>Sí</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setInfo(!info)
                                    } }>No</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={modify}
                                onClose={() => setModify(!modify)}
                                color="warning">
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel>Para modificar el premio debe ingresar su contraseña</CLabel>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="password">Contraseña</CLabel>
                                            <CInput required
                                                    autoComplete="false"
                                                    id="password"
                                                    type="password"
                                                    value={currentPassword}
                                                    onChange={(e) => {
                                                        setCurrentPassword(e.target.value);
                                                    }}/>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-scrubber"/> Validar</CButton>
                                            <CButton color="secondary" onClick={() => {
                                                setModify(!modify)
                                            } }>Cancelar</CButton>
                                        </CCardFooter>
                                    </CForm>
                                </CModalBody>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={showReceipt}
                                onClose={() => {
                                    setShowReceipt(!showReceipt);
                                }}>
                                <CModalHeader closeButton>Tiquete</CModalHeader>
                                <CModalBody>
                                    <CRow>
                                        <CCol lg={12} className="text-center">
                                            <CImg src={sourceImg} fluid className="img-thumbnail"></CImg>
                                        </CCol>
                                    </CRow>
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="primary" onClick={() => {
                                        window.open(`/sale/getSaleReceiptDownload/${newBillNumber}`, '_blank', 'noreferrer');
                                    }}>Imprimir</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setShowReceipt(!showReceipt);
                                    }}>Cerrar</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{'Cierre y Movimientos'}</strong>
                                </CCardHeader>
                                <CCardBody className={"table-responsive"}>
                                    <CRow>
                                        <CCol lg={6}>
                                            <CLabel htmlFor="lottery">Sorteo</CLabel>
                                            <Select
                                                required
                                                defaultValue={selectedLottery}
                                                onChange={(e) => {
                                                    setSelectedLottery(e.value);
                                                }}
                                                placeholder={"Seleccione un sorteo"}
                                                noOptionsMessage={() => "No hay sorteos"}
                                                options={lotteriesOptions}/>
                                        </CCol>
                                        <CCol lg={6}>
                                            <CLabel htmlFor="fecha">Fecha del sorteo</CLabel>
                                            <DatePicker
                                                id="fecha"
                                                dateFormat="dd/MM/yyyy"
                                                selected={selectedDate}
                                                autoComplete="false"
                                                locale={es}
                                                required
                                                onChange={(date) => {
                                                    setSelectedDate(date);
                                                }}
                                                className="form-control"
                                            />
                                        </CCol>
                                    </CRow>
                                    <br/>
                                    <CRow className={"row-top"}>
                                        <CCol lg={3}>
                                            <hr/>
                                            <CRow>
                                                <CCol lg={12}>
                                                    <h4>Número premiado</h4>
                                                </CCol>
                                            </CRow>
                                            <CRow className={"row-top"}>
                                                <CCol lg={12}>
                                                    <Select
                                                        id="winner"
                                                        required
                                                        isDisabled={isPrizeDisabled}
                                                        value={numbers.filter(option => option.value === winnerNumber)}
                                                        onChange={(e) => {
                                                            const number = parseInt(e.value);
                                                            setWinnerNumber(isNaN(number) ? null : number);
                                                        }}
                                                        placeholder={"Seleccione un número"}
                                                        noOptionsMessage={() => "No hay números"}
                                                        options={numbers}/>
                                                </CCol>
                                            </CRow>
                                            <CRow className={"row-top"}>
                                                <CCol lg={4}>
                                                    <CButton type="button" disabled={isDisabled} size="md" color="primary" className="mr-2" onClick={() => {
                                                        setInfo(!info);
                                                    }}>
                                                        <CIcon name="cil-save"/> Guardar
                                                    </CButton>
                                                </CCol>
                                                <CCol lg={4}>
                                                    <CButton type="button" disabled={!isDisabled} size="md" color="danger" className="mr-2" onClick={() => {
                                                        setModify(!modify);
                                                    }}>
                                                        <CIcon name="cil-pencil"/> Modificar
                                                    </CButton>
                                                </CCol>
                                                <CCol lg={4}>
                                                    <CButton type="button" disabled={!isDisabled} size="md" color="primary" className="mr-2" onClick={() => {
                                                        applyPrize()
                                                    }}>
                                                        <CIcon name="cil-save"/> Aplicar premios
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                            <hr/>
                                            <CRow className={"row-top"}>
                                                <CCol lg={12}>
                                                    <h4>Caja</h4>
                                                </CCol>
                                            </CRow>
                                            <CRow className={"row-top"}>
                                                <CCol lg={12}>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td><CLabel tag={"strong"}>Total de la lista:</CLabel></td>
                                                            <td>{totalAmount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><CLabel tag={"strong"}>Premio:</CLabel></td>
                                                            <td>{prize}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><CLabel tag={"strong"}>Comisión:</CLabel></td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td><CLabel tag={"strong"}>Saldo lista:</CLabel></td>
                                                            <td>{balance}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol lg={9}>
                                            <CRow>
                                                <CCol lg={3}>
                                                    <h5>Detalles de movimientos de cierres</h5>
                                                </CCol>
                                                <CCol lg={3}>
                                                    <CLabel htmlFor="role">Zonas</CLabel>
                                                    <Select
                                                        required
                                                        value={projects.filter(option => option.value === selectedProjectId)}
                                                        onChange={(e) => {
                                                            setSelectedProjectId(e.value);
                                                        }}
                                                        placeholder={"Todos"}
                                                        noOptionsMessage={() => "No hay zonas"}
                                                        options={projects}/>
                                                </CCol>
                                                <CCol lg={2}>
                                                    <CLabel htmlFor="role">Rol de Usuario</CLabel>
                                                    <Select
                                                        required
                                                        value={roles.filter(option => option.value === selectedRoleId)}
                                                        onChange={(e) => {
                                                            setSelectedRoleId(e.value);
                                                        }}
                                                        placeholder={"Todos"}
                                                        noOptionsMessage={() => "No hay roles"}
                                                        options={roles}/>
                                                </CCol>
                                                <CCol lg={2}>
                                                    <CLabel htmlFor="user">Usuario</CLabel>
                                                    <Select
                                                        required
                                                        value={users.filter(option => option.value === selectedUserId)}
                                                        onChange={(e) => {
                                                            setSelectedUserId(e.value);
                                                        }}
                                                        placeholder={"Todos"}
                                                        noOptionsMessage={() => "No hay usuarios"}
                                                        options={users}/>
                                                </CCol>
                                                <CCol lg={2}>
                                                    <CLabel htmlFor="user">Sólo números premiados</CLabel>
                                                    <CSwitch
                                                        color={'primary'}
                                                        checked={onlyWinnerNumbers}
                                                        labelOn={"Si"}
                                                        labelOff={"No"}
                                                        onChange={(e) => {
                                                            setOnlyWinnerNumbers(e.target.checked);
                                                        }}
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg={12}>
                                                    <CDataTable
                                                        items={movements}
                                                        fields={movementsFields}
                                                        columnFilter
                                                        noItemsView={{noResults: 'No hay movimientos', noItems: 'No hay movimientos'}}
                                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                                        footer
                                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                                        itemsPerPage={5}
                                                        hover
                                                        sorter
                                                        pagination
                                                        scopedSlots={{
                                                            'prizeApplied':
                                                                (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.prizeApplied ? "Sí" : "No"}
                                                                        </td>
                                                                    )
                                                                },
                                                            'prizePaid':
                                                                (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.prizePaid ? "Sí" : "No"}
                                                                        </td>
                                                                    )
                                                                },
                                                            'isCancelled':
                                                                (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.isCancelled ? "Sí" : "No"}
                                                                        </td>
                                                                    )
                                                                },
                                                            'show_details':
                                                                (item, index)=>{
                                                                    return (
                                                                        <td className="py-2">
                                                                            <CButton
                                                                                color="primary"
                                                                                variant="outline"
                                                                                shape="square"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                    setNewBillNumber(item.bill);
                                                                                    crudService.get(`/sale/getSaleReceipt/${item.bill}`, (receipt) => {
                                                                                        setSourceImg(`data:image/png;base64,${receipt}`);
                                                                                        setShowReceipt(true);
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {'Ver tiquete'}
                                                                            </CButton>
                                                                        </td>
                                                                    )
                                                                },
                                                        }}
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderMovementsForm())
}

export default Movements
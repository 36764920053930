import {authenticationService} from '../_services/authentication.service';

export function handleResponse(response) {
    return new Promise(function (resolve, reject) {
        try {
            const data = response.data;
            if (response.status !== 200) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    authenticationService.logout();
                    forceReload();
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            resolve(data);
        } catch (ex) {
            reject(new Error(ex));
        }
    });
}

function forceReload() {
    const form = document.createElement('form');
    form.method = "POST";
    form.action = window.location.href;
    document.body.appendChild(form);
    form.submit();
}
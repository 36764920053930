import React, {useEffect} from 'react'
import {
    CToaster,
    CToast,
    CToastHeader,
    CToastBody
} from '@coreui/react'

function Alert({className, text, show}) {
    return (
        <CToaster
            position='top-right'
            key={`toaster${new Date().toLocaleString()}`}>
            <CToast
                key={`toast${new Date().toLocaleString()}`}
                show={show}
                autohide={5000}
                fade={true}
            >
                <CToastHeader closeButton={true}>
                    Mensaje
                </CToastHeader>
                <CToastBody className={className}>
                    {text}
                </CToastBody>
            </CToast>
        </CToaster>
)
}

export default Alert
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import {crudService} from "../../_services/crud.service";
import {
    CCard,
    CButton,
    CCardBody,
    CCardHeader,
    CCol,
    CFormGroup,
    CInput,
    CCardFooter,
    CLabel,
    CRow,
    CForm,
    CContainer,
    CInvalidFeedback, CInputGroupText, CInputGroupAppend, CInputGroup, CInputGroupPrepend
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {alertService} from "../../_services/alert.service";
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const Project = ({match}) => {
    const history = useHistory();
    const [existingProject, setProject] = useState({});
    const [countryCurrencySign, setCountryCurrencySign] = useState(null);
    const { id } = match.params;
    const isAddMode = !id;

    useEffect(() => {
        async function fetchCountryCurrencySign() {
            crudService.get('User/getCountryCurrencySign', (currencySign) => {
                setCountryCurrencySign(currencySign);
            }, 'Ocurrió un error obteniendo el símbolo de la moneda.')
        }

        fetchCountryCurrencySign();
    }, []);
    
    useEffect(() => {
        if (!isAddMode) {
            async function fetchProject() {
                crudService.get(`project/${id}`, setProject, 'Ocurrió un error obteniendo el proyecto')
            }

            fetchProject()
        }
    }, []);
    
    function updateProject(){
        crudService.update('/project', 
            existingProject,
            setProject,
            'El proyecto se modificó correctamente.',
            'Ocurrió un error modificando el proyecto.');
    } 
    
    function createProject() {
        crudService.create('/project', 
            existingProject,
            (response) => {
                alertService.success('El proyecto se creó correctamente.');
                setProject(response.data);
                history.push('/projects')
            },
            'Ocurrió un error creando el proyecto.')
    }

    const renderProjectForm = () => {
        const handleSubmit = event => {
            event.preventDefault();
            
            const form = event.currentTarget;
            
            if (form.checkValidity()) {
                isAddMode ? createProject() : updateProject();
            }
        }
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={10}>
                            <CCard>
                                <CCardHeader>
                                    <strong>{isAddMode ? 'Crear proyecto' : 'Editar proyecto'}</strong>
                                </CCardHeader>
                                <CCardBody>
                                    <CForm className="was-validated"
                                           onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <CLabel htmlFor="name">Nombre</CLabel>
                                            <CInput type="text"
                                                    autoComplete="false"
                                                    required
                                                    id="name"
                                                    defaultValue={existingProject.name}
                                                    onChange={(e) => {
                                                        setProject({...existingProject, name: e.target.value});
                                                    }}
                                            />
                                            <CInvalidFeedback>El nombre es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="bonus">Bono</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInput type="number"
                                                        autoComplete="false"
                                                        required
                                                        id="bonus"
                                                        step="0.1"
                                                        defaultValue={existingProject.bonus}
                                                        onChange={(e) => {
                                                            setProject({...existingProject, bonus: e.target.value});
                                                        }}
                                                />
                                                <CInputGroupAppend>
                                                    <CInputGroupText>%</CInputGroupText>
                                                </CInputGroupAppend>
                                                <CInvalidFeedback invalid="false">El bono es requerido.</CInvalidFeedback>
                                            </CInputGroup>
                                            <CInvalidFeedback>El bono es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="supervisorBonus">Bono del supervisor</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInput type="number"
                                                        autoComplete="false"
                                                        required
                                                        id="supervisorBonus"
                                                        step="0.1"
                                                        defaultValue={existingProject.supervisorBonus}
                                                        onChange={(e) => {
                                                            setProject({...existingProject, supervisorBonus: e.target.value});
                                                        }}
                                                />
                                                <CInputGroupAppend>
                                                    <CInputGroupText>%</CInputGroupText>
                                                </CInputGroupAppend>
                                                <CInvalidFeedback invalid="false">El bono es requerido.</CInvalidFeedback>
                                            </CInputGroup>
                                            <CInvalidFeedback>El bono es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="administratorBonus">Bono del administrador</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInput type="number"
                                                        autoComplete="false"
                                                        required
                                                        id="administratorBonus"
                                                        step="0.1"
                                                        defaultValue={existingProject.administratorBonus}
                                                        onChange={(e) => {
                                                            setProject({...existingProject, administratorBonus: e.target.value});
                                                        }}
                                                />
                                                <CInputGroupAppend>
                                                    <CInputGroupText>%</CInputGroupText>
                                                </CInputGroupAppend>
                                                <CInvalidFeedback invalid="false">El bono es requerido.</CInvalidFeedback>
                                            </CInputGroup>
                                            <CInvalidFeedback>El bono es requerido.</CInvalidFeedback>
                                        </CFormGroup>
                                        <CFormGroup>
                                            <CLabel htmlFor="goal">Meta</CLabel>
                                            <CInputGroup className="input-prepend">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>{countryCurrencySign}</CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput type="number"
                                                        autoComplete="false"
                                                        required
                                                        id="goal"
                                                        defaultValue={existingProject.goal}
                                                        onChange={(e) => {
                                                            setProject({...existingProject, goal: e.target.value});
                                                        }}
                                                />
                                                <CInvalidFeedback>La meta es requerida.</CInvalidFeedback>
                                            </CInputGroup>
                                        </CFormGroup>
                                        <CCardFooter>
                                            <CButton type="submit" size="md" color="primary" className="mr-2"><CIcon
                                                name="cil-save"/> Guardar</CButton>
                                            <Link to='/projects' className="btn btn-link">Cancelar</Link>
                                        </CCardFooter>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        );
    }

    return (renderProjectForm(existingProject))
}

export default Project

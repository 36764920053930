import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import './client/polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './client/App';
import * as serviceWorker from './client/serviceWorker';
import {icons} from './client/assets/icons'
import axios from "axios";
import {Provider} from 'react-redux'
import store from './client/store'
import {authenticationService} from "./client/_services/authentication.service";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import { Alert } from '../node_modules/@coreui/coreui/dist/js/coreui';
import { alertService } from './client/_services/alert.service';

React.icons = icons
const history = createBrowserHistory();

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const response = error.response;
    if (response.status !== 200) {
        if ([401, 403].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            authenticationService.logout();
            return;
        }
        
        return Promise.reject(error.response);
    }
});

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

import React, {useEffect, useState} from 'react';
import {
    TheContent,
    TheFooter,
    TheHeader
} from './index'
import { getAuthToken } from '@magicbell/webpush';
import { subscribe } from '@magicbell/webpush';
import {authenticationService} from "../_services/authentication.service";
import { createHmac } from 'crypto';
import {crudService} from "../_services/crud.service";

const TheLayout = () => {
    useEffect(() => {
        async function fetchToken() {
            const key = '95e91a9f1f6dbb8b8a57fd19e95e7f7848b0357b';
            const token = await getAuthToken({
                apiKey: key,
                userEmail: authenticationService.getUserEmail,
                userHmac: authenticationService.generateHmac(authenticationService.getUserEmail),
                userExternalId: createToken(authenticationService.getUserId, key),
            });
            
            localStorage.setItem('magicalApiToken', token.token);
            localStorage.setItem('magicalApiProject', token.project);
            await subscribe({
                token: token.token,
                project: token.project,
                serviceWorkerPath: '/sw.js',
            });
        }

        fetchToken();
    }, []);
    
    return (
        <div className="c-app c-default-layout">
            <div className="c-wrapper">
                <TheHeader/>
                <div className="c-body">
                    <TheContent/>
                </div>
                <TheFooter/>
            </div>
        </div>
    )
}

function createToken(userId, key) {
    const hmac = createHmac('sha256', key);
    hmac.update(userId, 'utf8');
    return hmac.digest('base64');
}

export default TheLayout

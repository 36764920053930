import { authenticationService } from '../_services/authentication.service';

export function authHeader(includeContentType) {
    const token = authenticationService.token;
    if (token) {
        const headers = { Authorization: `Bearer ${token}` };
        if (includeContentType) {
            headers['Content-Type'] = 'application/json';
        }
        return headers;
    } else {
        return {};
    }
}
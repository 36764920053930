import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CSubheader,
    CContainer,
    CForm,
    CInput,
    CImg,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow, CFormGroup, CLabel
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {authenticationService} from "../../../_services/authentication.service";
import {crudService} from "../../../_services/crud.service";
import {selectHelper} from "../../../_helpers/select.helper";
import Select from "react-select";
import {alertService} from "../../../_services/alert.service";

const ForgotPassword = () => {
    const history = useHistory();
    const [login, setLogin] = useState({});
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        async function fetchCountries() {
            crudService.get('/country', (data) => {
                selectHelper.populateSelectControl(data, "id", "name", setCountries);
            }, 'Ocurrió un error listando los paises.');
        }

        fetchCountries();
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity()) {
            crudService.post('/user/forgotPassword',
                login,
                () => {
                    alertService.success('Se ha cambiado la contraseña.');
                    history.push('/login')
                },
                'Ocurrió un error recuperando la contraseña.')
        }
    }

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <div className="login">
                                        <CImg style={{"height": 65}}
                                              src={require("../../../assets/images/logo.png").default} fluid></CImg>
                                    </div>
                                    <CSubheader className="px-3 justify-content-center mx-auto row-bottom">
                                        
                                    </CSubheader>
                                    <CForm className="row-bottom" onSubmit={handleSubmit}>
                                        <p className="text-muted text-size">Recuperar contraseña</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput required
                                                    type="text"
                                                    placeholder="Nombre de usuario"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        setLogin({...login, username: e.target.value});
                                                    }}/>
                                        </CInputGroup>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-envelope-closed"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput required
                                                    type="email"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        setLogin({...login, email: e.target.value});
                                                    }}/>
                                        </CInputGroup>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked"/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput required
                                                    type="password"
                                                    placeholder="Nueva Contraseña"
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        setLogin({...login, password: e.target.value});
                                                    }}/>
                                        </CInputGroup>
                                        <CFormGroup>
                                            <Select
                                                required
                                                onChange={(e) => {
                                                    setLogin({...login, countryId: e.value});
                                                }}
                                                placeholder={"Seleccione un país"}
                                                noOptionsMessage={() => "No hay paises"}
                                                options={countries}/>
                                        </CFormGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton type="submit" color="primary" className="px-4">Recuperar contraseña</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                    <div className= "login">
                                        <CImg style={{height: 50}}
                                              src={require("../../../assets/images/fundeporte.jpg").default}></CImg>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default ForgotPassword
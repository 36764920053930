import React  from "react";
import {SecureHelper} from "./_helpers/secure.helper";
import {Redirect} from "react-router-dom";

const AuthProvider = ({children, ...props}) => {
    if (!SecureHelper.canAccess(props.roles)) {
        return (<Redirect to='/login'/>)
    } else {
        return (children);
    }
}

export default AuthProvider
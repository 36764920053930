import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {crudService} from "../../_services/crud.service";
import {
    CContainer,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CDataTable,
    CCard,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CProgress,
    CProgressBar
} from '@coreui/react'
import {Role} from "../../_helpers/role";
import AuthProvider from "../../AuthProvider";

const fields = [
    {key: 'name', label: 'Nombre', _style: {width: '20%'}},
    {key: 'bonus', label: 'Bono Promotor %', _style: {width: '15%'}},
    {key: 'administratorBonus', label: 'Bono Administrador %', _style: {width: '15%'}},
    {key: 'supervisorBonus', label: 'Bono Supervisor %', _style: {width: '15%'}},
    {key: 'goalWithCurrency', label: 'Meta', _style: {width: '15%'}},
    {key: 'currentGoal', label: 'Avance %', _style: {width: '15%'}},
    {key: 'currentBucket', label: 'Fondo proyecto', _style: {width: '15%'}},
    {key: 'totalAmount', label: 'Monto recaudado', _style: {width: '15%'}},
    {
        key: 'show_details',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    },
    {
        key: 'delete',
        label: '',
        _style: {width: '1%'},
        sorter: false,
        filter: false
    }
]

const Projects = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [projects, setProjects] = useState([])
    const [danger, setDanger] = useState(false)
    const [selectedProject, setSelectedProject] = useState({})
    
    function deleteProject() {
        crudService.remove('/project', 
            selectedProject,
            setSelectedProject,
            fetchProjects,
            'El proyecto se se eliminó correctamente.',
            'Ocurrió un error eliminando el proyecto.')
    }
    
    async function fetchProjects() {
        await crudService.list('project', setProjects, setLoading);
    }

    const renderProjectsTable = () => {
        return (
            <AuthProvider roles={[Role.Manager]}>
                <CContainer fluid>
                    <CRow>
                        <CCol lg={12}>
                            <CModal
                                show={danger}
                                onClose={() => setDanger(!danger)}
                                color="danger"
                            >
                                <CModalHeader closeButton>
                                    <CModalTitle>Confirmación</CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                    ¿Está seguro que desea cerrar el proyecto? Tome en cuenta que esto elimina el acceso a los usuario asignados a este proyecto. Está acción no se puede revertir.
                                </CModalBody>
                                <CModalFooter>
                                    <CButton color="danger" onClick={() => {
                                        setDanger(!danger);
                                        deleteProject();
                                    }}>Sí</CButton>{' '}
                                    <CButton color="secondary" onClick={() => {
                                        setDanger(!danger)
                                        setSelectedProject({})
                                    } }>No</CButton>
                                </CModalFooter>
                            </CModal>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg={12}>
                            <CCard>
                                <CCardHeader>
                                    <CRow>
                                        <CCol lg={6}>
                                            <strong>Lista de zonas</strong><br/>
                                        </CCol>
                                        <CCol lg={6}>
                                            <CButton onClick={() => {
                                                history.push(`/projects/create`)
                                            }} type="button" size="md" color="primary" className="mr-2 float-right">+ Crear nuevo proyecto</CButton>
                                        </CCol>
                                    </CRow>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                        items={projects}
                                        fields={fields}
                                        columnFilter
                                        noItemsView={{noResults: 'No hay zonas', noItems: 'No hay zonas'}}
                                        tableFilter={{placeholder: 'Buscar', label: 'Buscar:'}}
                                        footer
                                        itemsPerPageSelect={{label: 'Filas por página'}}
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            'currentGoal': (item, index) => {
                                                return (<td>
                                                    <CProgress size="lg" className="mb-1 bg-white">
                                                        <CProgressBar
                                                            showValue
                                                            showPercentage
                                                            precision={2}
                                                            value={item.currentGoal}
                                                            color="success"
                                                            height="30px"
                                                        />
                                                        <CProgressBar
                                                            showValue
                                                            showPercentage
                                                            precision={2}
                                                            value={100 - item.currentGoal}
                                                            color="dark"
                                                        />
                                                    </CProgress>
                                                </td>)
                                            },
                                            'show_details':
                                                (item, index) => {
                                                    return (
                                                        <td className="py-2">
                                                            <CButton
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                                onClick={() => {
                                                                    history.push(`/projects/edit/${item.id}`)
                                                                }}
                                                            >
                                                                Modificar
                                                            </CButton>
                                                        </td>
                                                    )
                                                },
                                            'delete': (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="danger"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                setDanger(!danger);
                                                                setSelectedProject(item);
                                                            }}
                                                        >
                                                            Cerrar
                                                        </CButton>
                                                    </td>
                                                )
                                            }
                                        }}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </AuthProvider>
        )
    }

    useEffect(() => {
        fetchProjects()
    }, []);

    let contents = loading
        ? <p><em>Cargando...</em></p>
        : renderProjectsTable();

    return (contents)
}

export default Projects